@use 'src/ds/text-utils';

.CalendarPanel {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  &.is-opening {
    animation: calendar-slide-down 300ms forwards;
  }

  &.is-closing {
    animation: calendar-slide-up 150ms forwards;
  }

  &.is-hidden {
    display: none;
  }
}

.CalendarSheet {
  justify-content: center;
  display: flex;
  width: 100%;
  font-size: 16px;

  .react-datepicker {
    background-color: $white;
    box-shadow: none;
  }
}

.DateRangePickerCalendar,
.DatePickerCalendar {
  .react-datepicker__day-name {
    height: $calendar-day-dimension;
    width: $calendar-day-dimension + $calendar-day-gutter-x;

    &:first-child {
      width: $calendar-day-dimension + $calendar-day-gutter-x / 2;
      padding-right: $calendar-day-gutter-x / 2;
    }

    &:last-child {
      width: $calendar-day-dimension + $calendar-day-gutter-x / 2;
      padding-left: $calendar-day-gutter-x / 2;
    }
  }

  .react-datepicker__day {
    width: $calendar-day-dimension + $calendar-day-gutter-x;
    height: $calendar-day-dimension;
    line-height: $calendar-day-dimension !important;

    &:first-child {
      width: $calendar-day-dimension + $calendar-day-gutter-x / 2;
      justify-content: flex-start;
    }

    &:last-child {
      width: $calendar-day-dimension + $calendar-day-gutter-x / 2;
      justify-content: flex-end;
    }

    .Calendar-dayInner {
      width: $calendar-day-dimension;
    }
  }
}

.DatePickerMultiple {
  .react-datepicker,
  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__day-name {
    width: calc(100% / 7);
  }

  .react-datepicker__day {
    width: calc(100% / 7);
    display: flex;
    justify-content: center;
    height: 32px;

    .Calendar-dayInner {
      width: 32px;
    }
  }
}

.react-datepicker__day--today:hover .Calendar-dayInner {
  background-color: $blue-100;
  color: $blue-500;
}

.react-datepicker__day--today:focus .Calendar-dayInner {
  background-color: $blue-200;
}

.react-datepicker__day--selected .Calendar-dayInner {
  background-color: $blue-500 !important;
  color: $white;
}

.react-datepicker__day--selected:hover .Calendar-dayInner {
  color: $white !important;
}

.react-datepicker__day--selected.react-datepicker__day--today:hover .Calendar-dayInner {
  background-color: $blue-600 !important;
  border: 1px solid $blue-600 !important;
}

.react-datepicker__day-name {
  @include text-utils.specs(map-get($text-config-lg, body3));
  color: $gray-900;
  font-weight: 600;

  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@mixin calendar-day-hover {
  background-color: $gray-50;
  color: $black;
}

@mixin calendar-day-selected {
  background-color: $blue-500;
  color: $white;
}

.react-datepicker__day--keyboard-selected {
  background: none;

  .Calendar-dayInner:not(.is-disabled) {
    color: $black;
  }

  &:focus:not(.react-datepicker__day--range-end)
    :not(.react-datepicker__day--excluded)
    .Calendar-dayInner:not(.is-disabled) {
    @include calendar-day-hover;
  }
}

.react-datepicker__day--excluded {
  .Calendar-dayInner {
    color: $gray-100 !important;
  }
}

.react-datepicker__day--today {
  .Calendar-dayInner {
    border-radius: 50%;
    border: 1px solid $blue-500;

    &:hover {
      border-radius: 50%;
      background-color: $blue-100;
    }
  }

  &:not(.react-datepicker__day--selected) .Calendar-dayInner {
    color: $blue-500;
  }
}

.react-datepicker {
  border: none;
}

.react-datepicker__header {
  background-color: $white;
  border-bottom: none;
  padding: 0;
  position: relative;
}

.react-datepicker__day-names {
  margin: 8px 0 0 0;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day:hover {
  border-radius: 0;
}

.react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selecting-range-end):not(.react-datepicker__day--range-end):not(.react-datepicker__day--in-range):not(.react-datepicker__day--range-start):not(.react-datepicker__day--selecting-range-start):not(.react-datepicker__day--today) {
  .Calendar-dayInner:hover {
    @include calendar-day-hover;
  }

  &:focus .Calendar-dayInner {
    background-color: $gray-100;
  }
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  &:not(.react-datepicker__day--selecting-range-start) {
    background-color: $blue-100 !important;
  }

  &.react-datepicker__day--selecting-range-start.is-mouse-on-calendar {
    background-color: $blue-100 !important;
  }

  &.react-datepicker__day--selecting-range-start:hover {
    background: none !important;
  }

  &.react-datepicker__day--selecting-range-end:first-child,
  &.react-datepicker__day--range-end:first-child {
    background: none !important;
  }

  color: $black;
  border-radius: 0;

  &:hover .Calendar-dayInner {
    background-color: $blue-100;
  }

  .Calendar-dayInner {
    &:hover {
      background-color: $blue-100;
      border-radius: 0;
    }
  }
}

.DateRangePickerCalendar,
.DatePickerCalendar {
  .react-datepicker__day--today:hover:not(.react-datepicker__day--selected) .Calendar-dayInner {
    background-color: $blue-100;
    color: $blue-500;
  }

  .react-datepicker__day--today:focus:not(.react-datepicker__day--selecting-range-end):not(.react-datepicker__day--range-end):not(.react-datepicker__day--range-start):not(.react-datepicker__day--selecting-range-start)
    .Calendar-dayInner {
    background-color: $blue-200;
  }
}

.DatePickerCalendar .react-datepicker {
  min-height: 304px;
}

.react-datepicker__day {
  @include text-utils.specs(map-get($text-config-lg, body2));

  margin: 0;
  user-select: none;
  display: inline-flex;
  justify-content: center;

  &:hover {
    background: none;
  }

  &.react-datepicker__day--selected {
    background: none;
  }

  .Calendar-dayInner {
    height: 100%;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.is-disabled {
      color: $gray-100;
    }

    &.is-start-date,
    &.is-end-date {
      @include calendar-day-selected;
      border-radius: 50%;
    }
  }
}

@mixin calendar-day-range-start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.react-datepicker__day--range-start,
.react-datepicker__day--selecting-range-start {
  &:hover {
    @include calendar-day-range-start;
  }

  @include calendar-day-range-start;

  position: relative;
  left: $calendar-day-gutter-x / 2;

  .Calendar-dayInner {
    position: relative;
    right: $calendar-day-gutter-x / 2;
  }
}

@mixin calendar-day-range-end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.react-datepicker__day--range-end,
.react-datepicker__day--selecting-range-end {
  &:hover {
    @include calendar-day-range-end;
  }

  @include calendar-day-range-end;

  position: relative;

  right: $calendar-day-gutter-x / 2;

  .Calendar-dayInner {
    position: relative;
    left: $calendar-day-gutter-x / 2;
  }
}

.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
  right: $calendar-day-gutter-x / 2;
  left: -$calendar-day-gutter-x / 2;
}

.react-datepicker__day--selecting-range-end {
  .Calendar-dayInner,
  .Calendar-dayInner:hover {
    border-radius: 50%;
    background-color: $blue-500 !important;
    color: $white;
    font-weight: 600;
  }
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selected {
  color: $white;
  font-weight: 600;
}

.react-datepicker__week {
  display: flex;
  margin-top: $calendar-day-gutter-y;
}

.react-datepicker__day--range-start.react-datepicker__day--range-end {
  left: 0;
  right: 0;
  background: none !important;

  .Calendar-dayInner {
    left: 0;
    right: 0;
  }
}

@keyframes calendar-slide-down {
  from {
    transform: translateY(-12px);
    opacity: 0.3;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes calendar-slide-up {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-12px);
    opacity: 0;
  }
}
