@mixin size-config($size, $config) {
  .RadioButton.is-size-#{$size} {
    .RadioButton-label {
      margin-left: map-get($config, visual-value-gap);
      font-size: map-get($config, font-size);
      line-height: map-get($config, font-size);
    }

    .RadioButton-input {
      width: map-get($config, radio-button-dimension);
      height: map-get($config, radio-button-dimension);
    }

    .RadioButton-button {
      width: map-get($config, radio-button-dimension);
      height: map-get($config, radio-button-dimension);
    }
  }

  .RadioButton-sublabel.is-size-#{$size} {
    position: relative;
    left: map-get($config, radio-button-dimension) + map-get($config, visual-value-gap);
  }
}

.RadioButton {
  position: relative;

  .RadioButton-input {
    opacity: 0;
    outline: 0;
    appearance: none;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    margin: 0;
    top: 0;
    left: 0;
  }

  .RadioButton-button {
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    border: 2px solid $blue-500;
    opacity: 0.3;
  }

  &.is-checked {
    .RadioButton-button {
      border: 6px solid $blue-500;
      opacity: 1;
      cursor: auto;
    }

    .RadioButton-input {
      cursor: auto;
    }
  }

  &.is-disabled {
    .RadioButton-button {
      opacity: 0.3;
    }

    .RadioButton-input,
    .RadioButton-button {
      cursor: auto;
    }
  }
}

@each $size in sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
