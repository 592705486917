// shared
@import './BottomSheet';
@import './BottomDrawer';
@import './Table/Table';
@import './Popover';
@import './Button';
@import './DatePicker/Calendar';
@import './DatePicker/DatePicker.scss';
@import './Card';
@import './CarouselOld/Carousel';
@import './Checkbox';
@import './CheckmarkList';
@import './CustomerTable/CustomerTable';
@import './EllipsisMenu';
@import './Filters/Filters';
@import './ComboBox/ComboBox';
@import './GoogleButton';
@import './HangingImage';
@import './Icon';
@import './IconButton';
@import './inputs/Input';
@import './inputs/PhoneInput';
@import './inputs/LocationInput';
@import './inputs/TextArea';
@import './KebabMenu';
@import './Layout';
@import './Link';
@import './Lightbox/Lightbox';
@import './map/MapMarker';
@import './MenuButton';
@import './Modal';
@import './NumberStepper';
@import './OfficeLeadOwnersFacepile';
@import './Pressable';
@import './RichTextEditor';
@import './RadioButton';
@import './Select';
@import './SelectStatus';
@import './SupportTicket';
@import './inputs/PhonePrefix';
@import './Snackbar';
@import './Spinner/Spinner';
@import './Animate';
@import './text-align';
@import './SelectMenu';
@import './Text';
@import './TextButton';
@import './Toggle';
@import './Typeahead';
@import './VerificationCode';
@import './WorkspaceCard';
@import './WorkspaceCarousel/WorkspaceCarousel';

@each $width in 0, 1, 2, 4, 8 {
  .is-border-width-#{$width} {
    border-width: #{$width}px;
    border-style: solid;
  }
  .is-border-top-width-#{$width} {
    border-top-width: #{$width}px;
    border-style: solid;
  }
  .is-border-bottom-width-#{$width} {
    border-bottom-width: #{$width}px;
    border-style: solid;
  }
}

@each $value in 0, 2, 4, 8, 12, 16, 20, 24, 36, 40, 56, 80, 100, 136, 1000 {
  .is-border-radius-#{$value} {
    border-radius: #{$value}px;
  }

  @each $key in top-left, top-right, bottom-right, bottom-left {
    .is-border-#{$key}-radius-#{$value} {
      border-#{$key}-radius: #{$value}px;
    }
  }
}

.is-border-radius-circular {
  border-radius: 50%;
}

@each $side in top, right, bottom, left {
  .layout-has-border-#{$side} {
    border-#{$side}: 1px solid $gray-50;
  }
}

@each $style in dashed, dotted, solid {
  .layout-border-style-#{$style} {
    border-style: $style;
  }
}

.is-hover-semibold:hover {
  font-weight: 600 !important;

  p.Text.#{$ds-class-name},
  span.Text.#{$ds-class-name} {
    font-weight: 600 !important;
  }
}

.ClickableDiv {
  cursor: pointer;
}

.DeletableRow {
  .IconButton {
    opacity: 0;
  }

  &:hover {
    .IconButton {
      opacity: 1;
    }
  }
}
