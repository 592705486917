.Card {
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 10px 0;
  border-radius: $border-radius;
  background-color: $white;

  &.is-size-sm {
    padding: 16px;
  }

  &.is-size-md {
    padding: 24px;
  }

  &.is-size-lg {
    padding: 32px;
  }
}
