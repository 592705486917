.WorkspaceListCard,
.WorkspaceCard {
  .CarouselArrow {
    opacity: 0;
  }

  .WorkspaceListCard-seePrice {
    opacity: 0;
  }

  &:hover {
    .CarouselArrow {
      opacity: 0.8;
    }

    .IconButton {
      opacity: 0.8;
    }

    .WorkspaceListCard-seePrice {
      opacity: 1;
    }
  }
}

.PricePopover.is-animating {
  animation: fade-down 300ms forwards;
}

.PricePopover.is-visible {
  animation: fade-up 300ms forwards;
}
