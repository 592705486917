.Avatar-image {
  transition: width 180ms, height 180ms;
}

.Avatar {
  transition: border 180ms;

  @each $value in 0, 1, 2, 3, 4 {
    .Avatar-image.is-avatar-border-width-#{$value} {
      width: calc(100% - #{$value * 2}px);
    }

    &:hover .Avatar-image.is-avatar-hover-border-width-#{$value} {
      width: calc(100% - #{$value * 2}px);
    }
  }
}
