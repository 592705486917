@use 'src/ds/inputs';

@mixin size-config($size, $config) {
  .PhoneInput.#{$ds-class-name}.is-size-#{$size} {
    $font-size: map-get($config, font-size);
    $border-width: 1px;
    $height: map-get($config, height);
    $padding-right: map-get($config, inner-padding-x) - $border-width;

    .PhoneInput-label {
      pointer-events: none;
      font-size: $font-size;
      height: $height;
      left: map-get($config, inner-padding-x);
      line-height: $font-size;
      top: ($height - $font-size) / 2;
    }
  }
}

.PhoneInput {
  position: relative;

  .Input.has-error .PhonePrefix-display {
    opacity: 0.4;
  }

  .Input.has-error.has-value .PhonePrefix-display {
    opacity: 1;
  }
}

@each $size in sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
