.Spinner {
  animation: 2s linear infinite svg-animation;
  max-width: 100px;

  &.is-size-xxs {
    width: $spinner-size-xxs;
    height: $spinner-size-xxs;
  }

  &.is-size-xs {
    width: $spinner-size-xs;
    height: $spinner-size-xs;
  }

  &.is-size-sm {
    width: $spinner-size-sm;
    height: $spinner-size-sm;
  }

  &.is-size-md {
    width: $spinner-size-md;
    height: $spinner-size-md;
  }

  &.is-size-lg {
    width: $spinner-size-lg;
    height: $spinner-size-lg;
  }
}

.Spinner-circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}
