.CheckmarkList-option.is-not-selected {
  &:hover {
    &.is-desktop {
      p.Text {
        font-weight: 600;
      }

      .CheckmarkList-checkmark {
        opacity: 0.4;
      }
    }
  }

  &:active {
    &.is-desktop {
      .CheckmarkList-checkmark {
        opacity: 1;
      }
    }
  }
}
