.#{$ds-class-name}-animate {
  opacity: 0;
  animation-duration: 800ms;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &.is-visible {
    opacity: 1;
  }
}

@each $value in fade-up, fade-down, fade-in-from-left, fade-in-from-right, fade-in, fade-out, fade-in-from-top {
  .#{$ds-class-name}-#{$value}.is-visible {
    animation-name: $value;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(24px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-down {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(24px);
  }
}

@keyframes fade-in-from-left {
  0% {
    opacity: 0;
    transform: translateX(-24px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-from-top {
  0% {
    opacity: 0;
    transform: translateY(-24px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-to-top {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-24px);
  }
}

@keyframes fade-in-from-right {
  0% {
    opacity: 0;
    transform: translateX(24px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes rotate-180-down {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-180deg);
  }
}

@keyframes rotate-180-up {
  0% {
    transform: rotate(-180deg);
  }

  100% {
    transform: rotate(0);
  }
}
