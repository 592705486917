@mixin specs($config) {
  font-size: map-get($config, font-size);
  line-height: map-get($config, line-height);
  font-weight: map-get($config, font-weight);
  letter-spacing: map-get($config, letter-spacing);

  &.is-bold {
    font-weight: 700;
  }

  &.is-semibold {
    font-weight: 600;
  }

  &.is-not-bold {
    font-weight: 400;
  }

  &.is-not-semibold {
    font-weight: 400;
  }

  &.is-hover-semibold:hover {
    font-weight: 600;
  }
}

@mixin defaultBold($config) {
  font-weight: map-get($config, bold-font-weight);
}

@mixin config-to-rules($config) {
  font-size: map-get($config, font-size);
  line-height: map-get($config, line-height);
  font-weight: map-get($config, font-weight);
  letter-spacing: map-get($config, letter-spacing);

  &.is-bold {
    font-weight: 700;
  }

  &.is-semibold {
    font-weight: 600;
  }

  // TODO: is this needed?
  &.is-not-bold {
    font-weight: 400;
  }

  // TODO: is this needed?
  &.is-not-semibold {
    font-weight: 400;
  }

  &.is-regular {
    font-weight: 400;
  }

  &.is-hover-semibold:hover {
    font-weight: 600;
  }
}
