@mixin size-config($size, $config) {
  .Checkbox.is-size-#{$size} {
    .Checkbox-label {
      margin-left: map-get($config, visual-value-gap);
      font-size: map-get($config, font-size);
    }

    .Checkbox-input {
      width: map-get($config, checkbox-dimension);
      height: map-get($config, checkbox-dimension);
    }

    .Checkbox-box {
      width: map-get($config, checkbox-dimension);
      height: map-get($config, checkbox-dimension);
    }
  }
}

.Checkbox {
  .Checkbox-input {
    opacity: 0;
    outline: 0;
    appearance: none;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    margin: 0;
  }

  .Checkbox-box {
    border: 2px solid $blue-500;
    opacity: 0.3;
    border-radius: 4px;
    cursor: pointer;
    padding: 2px;
    display: inline-flex;
  }

  &.is-checked {
    .Checkbox-box {
      opacity: 1;
      background-color: $blue-500;
      border: none;
    }
  }

  &.is-disabled {
    .Checkbox-box {
      opacity: 0.3;
    }

    .Checkbox-input,
    .Checkbox-box {
      cursor: auto;
    }
  }

  .Checkbox-checkmark {
    display: inline-flex;

    svg {
      width: 100%;
      fill: $white;
    }
  }
}

@each $size in sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
