.Table-row {
  background-color: $white;
  border-bottom: 1px solid $gray-25;

  &:hover {
    background-color: $gray-25;
  }

  &.is-clickable {
    cursor: pointer;
  }
}

.Table-textFilter {
  animation-name: growTextFilter;
  animation-duration: 300ms;
  width: 304px;
}

@keyframes growTextFilter {
  from {
    width: 64px;
  }

  to {
    width: 304px;
  }
}

.Table-numberRangeFilter {
  animation-name: growNumberRangeFilter;
  animation-duration: 300ms;
  width: 304px;
}

@keyframes growNumberRangeFilter {
  from {
    width: 64px;
  }

  to {
    width: 304px;
  }
}

.Table-dateRangeFilter {
  animation-name: growDateRangeFilter;
  animation-duration: 300ms;
  width: 448px;
}

@keyframes growDateRangeFilter {
  from {
    width: 96px;
  }

  to {
    width: 448px;
  }
}

.Table-header {
  &:hover {
    .Table-defaultSortIcon {
      opacity: 1;
    }
  }

  .Table-defaultSortIcon {
    opacity: 0;

    &.is-touch {
      opacity: 1;
    }
  }

  .Table-defaultSortIcon:not(.is-sortable) {
    opacity: 0 !important;
  }
}

.Table-searchButton {
  opacity: 0;

  &.is-touch {
    opacity: 1;
  }

  &.is-active {
    opacity: 1;
  }

  animation-duration: 100ms;
  transition: opacity 100ms;
}

.Table-header {
  &:hover {
    .Table-sortControl.is-sortable {
      opacity: 1;
    }

    .Table-searchButton {
      opacity: 1;
    }
  }
}

.Table-row {
  .ArchiveWorkspaceCell {
    opacity: 0;
  }

  &:hover .ArchiveWorkspaceCell {
    opacity: 1;
  }
}
