.KebabMenu {
  z-index: 2;
  cursor: pointer;

  .KebabMenu-menu {
    width: max-content;
    top: 32px;
    display: none;
  }

  &:hover {
    background-color: $gray-200;

    .Icon {
      svg .Icon-fill-color {
        fill: $blue-500;
      }
    }

    .KebabMenu-menu {
      display: block;

      .KebabMenu-menuItem {
        cursor: pointer;

        &:hover {
          background-color: $blue-50;
        }
      }
    }
  }
}
