.DashboardWifi-container {
  .DashboardWifi-copyIcon {
    opacity: 0;
  }

  &:hover {
    .DashboardWifi-copyIcon {
      opacity: 1;
    }
  }
}
