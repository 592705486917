.Popover {
  z-index: 9999;

  &.is-tooltip {
    border-radius: 4px;

    padding: 4px 8px;

    &.is-content-size-lg {
      padding: 8px 12px;
    }
  }

  &.is-popover {
    border-radius: 12px;
    padding: 12px 16px;
    height: fit-content;
  }
}

.Popover.is-popover {
  opacity: 0;
  position: relative;

  &.is-visible {
    animation-name: popover-fade-up;
    animation-duration: 150ms;
    animation-fill-mode: forwards;
  }
}

.Popover-container {
  cursor: pointer;
}

// Popover visiblity control
.Popover.is-popover .Popover-caret {
  visibility: hidden;
}

.Popover.is-popover.is-visible {
  .Popover-caret::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
}

.Popover.is-popover:not(.is-visible) {
  visibility: hidden;
  z-index: -1;
}

// Tooltip visibility control
.Popover.is-tooltip {
  visibility: hidden;

  .Popover-caret {
    visibility: hidden;
  }
}

.Popover[data-show].is-tooltip {
  visibility: visible;

  .Popover-caret::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
}

.Popover[data-popper-placement^='top'] > .Popover-caret {
  bottom: -4px;
}

.Popover[data-popper-placement^='bottom'] > .Popover-caret {
  top: -4px;
}

.Popover[data-popper-placement^='left'] > .Popover-caret {
  right: -4px;
}

.Popover[data-popper-placement^='right'] > .Popover-caret {
  left: -4px;
}

.Popover-caret,
.Popover-caret::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.Popover[data-popper-reference-hidden='true'].is-tooltip,
.Popover[data-popper-reference-hidden='true'].is-popover {
  visibility: hidden;
  pointer-events: none;

  .Popover-caret::before {
    visibility: hidden;
  }
}

@keyframes popover-fade-up {
  0% {
    opacity: 0;
    top: 12px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}
