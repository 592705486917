@use './media';

$ios-and-safari-select-options-offset: 13px;
$chrome-select-options-offset: 8px;
$firefox-select-options-offset: 0;

@mixin size-config($size, $config) {
  .SelectStatus.#{$ds-class-name}.is-size-#{$size} {
    $font-size: map-get($config, font-size);
    $height: map-get($config, height);
    $small-label-line-height: map-get($config, small-label-line-height);
    $small-label-font-size: map-get($config, small-label-font-size);
    $line-height: map-get($config, line-height);

    font-size: $font-size;
    height: $height;
    border-radius: 8px;
    padding-left: 12px;

    &.is-focused {
      .SelectStatus-select {
        padding-left: 8px;

        &.is-caret-hidden {
          padding-right: map-get($config, inner-padding-x);
        }

        @include media.is-chrome {
          padding-left: map-get($config, padding-x) - $chrome-select-options-offset - 1px;
        }

        @include media.is-ios-browsers-and-safari {
          padding-left: map-get($config, padding-x) - $ios-and-safari-select-options-offset - 1px;
        }

        @include media.is-firefox {
          padding-left: map-get($config, padding-x) - $firefox-select-options-offset - 1px;
        }
      }

      .SelectStatus-chevronContainer {
        right: map-get($config, padding-x) - 1px;
      }

      .SelectStatus-label {
        left: map-get($config, inner-padding-x) - 1px;
        top: ($height - ($small-label-line-height + $line-height)) / 2 - 1px;
      }
    }

    .SelectStatus-select {
      height: $height;
      font-size: $font-size;
      padding-left: 8px;

      &.is-caret-hidden {
        padding-right: map-get($config, inner-padding-x);
      }

      @include media.is-chrome {
        padding-left: map-get($config, padding-x) - $chrome-select-options-offset;
      }

      @include media.is-ios-browsers-and-safari {
        padding-left: map-get($config, padding-x) - $ios-and-safari-select-options-offset;
      }

      @include media.is-firefox {
        padding-left: map-get($config, padding-x) - $firefox-select-options-offset;
      }

      &.has-label.has-value {
        color: $gray-900;
        padding-top: map-get($config, small-label-line-height);
      }
    }

    &.has-dot {
      &.is-focused {
        .SelectStatus-select {
          padding-left: 7px;
        }

        .SelectStatus-dotContainer {
          left: map-get($config, padding-x) - 1;
        }
      }

      .SelectStatus-select {
        padding-left: 8px;
      }
    }

    &.has-label.has-value {
      .SelectStatus-dotContainer {
        padding-top: map-get($config, small-label-line-height);
      }
    }

    .SelectStatus-chevronContainer {
      right: map-get($config, padding-x);
    }

    .SelectStatus-label {
      position: absolute;
      font-weight: 600;
      color: $blue-500;
      left: map-get($config, inner-padding-x);
      top: ($height - ($small-label-line-height + $line-height)) / 2;
      font-size: $small-label-font-size;
      line-height: $small-label-line-height;
    }
  }
}

select.SelectStatus-select {
  font: unset;
  font-family: $font-family;
  appearance: none;
  background-color: transparent;
  border-radius: $input-border-radius;
  outline: none;
  border: none;
  z-index: 1;

  @include media.is-chrome {
    position: relative;
    left: $chrome-select-options-offset;
  }

  @include media.is-ios-browsers-and-safari {
    position: relative;
    left: $ios-and-safari-select-options-offset;
  }

  @include media.is-firefox {
    position: relative;
    left: $firefox-select-options-offset;
  }
}

.SelectStatus {
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: $white;
  border-radius: $input-border-radius;
  border: $input-border-width solid $gray-50;

  &.is-color-gray-900 {
    select {
      color: $gray-900;
    }
  }

  &.is-color-gray-300 {
    select {
      color: $gray-300;
    }
  }

  &:hover {
    background-color: $gray-50;
  }

  &.is-focused {
    border: $input-focus-border-width solid $blue-500;
  }

  &.is-disabled {
    .SelectStatus-select {
      opacity: 1;
      cursor: not-allowed;

      option {
        color: $gray-900;
      }
    }
  }

  &.is-semibold {
    font-weight: $control-font-weight;
  }

  &.is-full-width {
    width: 100%;

    .SelectStatus-select {
      width: 100%;
    }
  }
}

.SelectStatus-dotContainer {
  position: absolute;
  display: inline-flex;
}

.SelectStatus-chevronContainer {
  position: absolute;
  display: inline-flex;
}

@each $size in xxs, xs, sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
