.CarouselCard-joinButton {
  opacity: 0;
}

.CarouselCard:hover {
  .CarouselCard-joinButton {
    animation: carousel-join 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s 1 normal both;
  }
}

.CarouselCard {
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 420ms;

  &:hover {
    background-color: rgba($blue-50, 1);
  }
}

@keyframes carousel-join {
  0% {
    opacity: 0;
    top: 24px;
    right: 8px;
  }

  100% {
    opacity: 1;
    top: 8px;
    right: 8px;
  }
}

.CalendarCarouselContainer.is-desktop {
  &.is-showing {
    animation-name: carousel-z-index;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
  }
}

@keyframes carousel-z-index {
  0% {
    z-index: -1;
  }

  100% {
    z-index: 0;
  }
}

.CalendarCarousel.is-phone.is-visible {
  animation-duration: 400ms;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-name: show-calendar-carousel;
}

.CalendarCarousel.is-phone:not(.is-visible) {
  animation-duration: 300ms;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-name: hide-calendar-carousel;
  opacity: 0;
  transform: translateY(-64px);
}

@keyframes show-calendar-carousel {
  0% {
    height: 0;
    transform: translateY(-64px);
    opacity: 0;
  }

  25% {
    height: 100px;
  }

  40% {
    opacity: 0;
  }

  50% {
    height: 120px;
    transform: translateY(-64px);
  }

  80% {
    height: 154px;
    transform: translateY(-48px);
  }

  100% {
    height: 154px;
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes hide-calendar-carousel {
  0% {
    height: 154px;
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    height: 0;
    opacity: 0;
    transform: translateY(-64px);
  }
}
