.CustomerTable-row {
  display: flex;
  justify-content: space-between;
  height: 88px;
  border-bottom: 1px solid $gray-50;

  &.is-clickable {
    &:hover {
      background-color: $gray-25;
    }

    cursor: pointer;
  }
}
