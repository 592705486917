@use './media';

$ios-and-safari-select-options-offset: 13px;
$chrome-select-options-offset: 8px;
$firefox-select-options-offset: 0;

@mixin size-config($size, $config) {
  $font-size: map-get($config, font-size);
  $height: map-get($config, height);
  $small-label-line-height: map-get($config, small-label-line-height);
  $small-label-font-size: map-get($config, small-label-font-size);
  $line-height: map-get($config, line-height);

  .#{$ds-class-name}.Select-outerContainer.is-size-#{$size} {
    &.is-inline {
      display: flex;
      align-items: center;

      label {
        font-size: $font-size;
        line-height: $line-height;
        height: $height;
      }
    }

    .Select-inlineLabelContainer {
      flex-shrink: 0;
    }
  }

  .Select.#{$ds-class-name}.is-size-#{$size} {
    font-size: $font-size;
    height: $height;
    border-radius: 8px;

    &.is-focused {
      .Select-select {
        padding-left: map-get($config, padding-x);
        padding-right: map-get($config, inner-padding-x-with-icon) - 1;

        &.is-caret-hidden {
          padding-right: map-get($config, inner-padding-x);
        }

        @include media.is-chrome {
          padding-left: map-get($config, padding-x) - $chrome-select-options-offset - 1px;
        }

        @include media.is-ios-browsers-and-safari {
          padding-left: map-get($config, padding-x) - $ios-and-safari-select-options-offset - 1px;
        }

        @include media.is-firefox {
          padding-left: map-get($config, padding-x) - $firefox-select-options-offset - 1px;
        }
      }

      .Select-caretContainer {
        right: map-get($config, padding-x) - 1px;
      }

      .Select-label {
        left: map-get($config, inner-padding-x) - 1px;
        top: ($height - ($small-label-line-height + $line-height)) / 2 - 1px;
      }
    }

    .Select-select {
      height: $height;
      font-size: $font-size;
      padding-left: map-get($config, padding-x);
      padding-right: map-get($config, inner-padding-x-with-icon);

      &.is-caret-hidden {
        padding-right: map-get($config, inner-padding-x);
      }

      @include media.is-chrome {
        padding-left: map-get($config, padding-x) - $chrome-select-options-offset;
      }

      @include media.is-ios-browsers-and-safari {
        padding-left: map-get($config, padding-x) - $ios-and-safari-select-options-offset;
      }

      @include media.is-firefox {
        padding-left: map-get($config, padding-x) - $firefox-select-options-offset;
      }

      &:not(.has-value) {
        color: $gray-400;
      }

      &.has-label.has-value {
        color: $gray-900;
        padding-top: map-get($config, small-label-line-height);
      }
    }

    &.has-icon {
      &.is-focused {
        .Select-select {
          padding-left: map-get($config, inner-padding-x-with-icon) - 1px;
        }
      }

      .Select-select {
        padding-left: map-get($config, inner-padding-x-with-icon);
      }
    }

    .Select-iconContainer {
      left: map-get($config, padding-x);
    }

    &.has-label.has-value {
      .Select-iconContainer {
        padding-top: map-get($config, small-label-line-height);
      }
    }

    .Select-caretContainer {
      right: map-get($config, padding-x);
    }

    .Select-label {
      position: absolute;
      font-weight: 600;
      color: $blue-500;
      left: map-get($config, inner-padding-x);
      top: ($height - ($small-label-line-height + $line-height)) / 2;
      font-size: $small-label-font-size;
      line-height: $small-label-line-height;
    }
  }
}

select.Select-select {
  font: unset;
  font-family: $font-family;
  appearance: none;
  background-color: transparent;
  border-radius: $input-border-radius;
  outline: none;
  border: none;
  z-index: 1;

  @include media.is-chrome {
    position: relative;
    left: $chrome-select-options-offset;
  }

  @include media.is-ios-browsers-and-safari {
    position: relative;
    left: $ios-and-safari-select-options-offset;
  }

  @include media.is-firefox {
    position: relative;
    left: $firefox-select-options-offset;
  }
}

.Select {
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: $white;
  border-radius: $input-border-radius;

  &.is-inline {
    border: $input-border-width solid transparent;
  }

  &:not(.is-inline) {
    border: $input-border-width solid $gray-50;
  }

  // Necessary to cutoff long option values on mobile
  overflow: hidden;

  &.is-color-gray-900 {
    select {
      color: $gray-900;
    }
  }

  &.is-color-gray-300 {
    select {
      color: $gray-300;
    }
  }

  &:hover {
    background-color: $gray-50;
  }

  &.is-focused {
    border: $input-focus-border-width solid $blue-500;
  }

  &.has-error {
    border: 2px solid $red-700;
  }

  &.is-disabled {
    .Select-select {
      opacity: 0.6;
      cursor: not-allowed;

      option {
        color: $gray-900;
      }
    }
  }

  option.Select-labelOption {
    color: $gray-400;
  }

  &.is-semibold {
    font-weight: $control-font-weight;
  }

  &.is-full-width {
    width: 100%;

    .Select-select {
      width: 100%;
    }
  }
}

.Select-iconContainer {
  position: absolute;
  display: inline-flex;
}

.Select-caretContainer {
  position: absolute;
  display: inline-flex;
}

@each $size in xxs, xs, sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
