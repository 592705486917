.Icon {
  display: inline-block;

  svg {
    display: inherit;
  }

  &.is-size-xxs {
    width: $icon-size-xxs;
    min-width: $icon-size-xxs;
    height: $icon-size-xxs;
  }

  &.is-size-xs {
    width: $icon-size-xs;
    min-width: $icon-size-xs;
    height: $icon-size-xs;
  }

  &.is-size-sm {
    width: $icon-size-sm;
    min-width: $icon-size-sm;
    height: $icon-size-sm;
  }

  &.is-size-md {
    width: $icon-size-md;
    min-width: $icon-size-md;
    height: $icon-size-md;
  }

  &.is-size-lg {
    width: $icon-size-lg;
    min-width: $icon-size-lg;
    height: $icon-size-lg;
  }

  &.is-size-xl {
    width: $icon-size-xl;
    min-width: $icon-size-xl;
    height: $icon-size-xl;
  }

  &.is-size-xxl {
    width: $icon-size-xxl;
    min-width: $icon-size-xxl;
    height: $icon-size-xxl;
  }

  &.is-size-xxxl {
    width: $icon-size-xxxl;
    min-width: $icon-size-xxxl;
    height: $icon-size-xxxl;
  }

  @each $degrees in 90, 180, 270 {
    &.is-rotation-#{$degrees} {
      transform: rotate(#{$degrees}deg);
    }
  }
}
