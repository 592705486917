.Testimonials-video:hover:before {
  z-index: 0;
  opacity: 0;
}

.Testimonials-video:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  z-index: 1;
  opacity: 1;
  transition: opacity 300ms;
}
