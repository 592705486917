@use 'media';

.SupportTicketMessage {
  @include media.md-up {
    .MessageEmojiSelector:not(.is-open).is-hoverable {
      opacity: 0;
    }

    &:hover {
      .MessageEmojiSelector.is-hoverable {
        opacity: 1;
      }
    }
  }
}

.SupportTicketStatusSelector-menuItem {
  &:hover {
    background-color: $gray-100;
  }
}

.MessageEmojiSelector-button {
  border-width: 1px;
  border-color: $gray-50;

  &.has-emojis {
    background-color: $gray-25;
  }

  @include media.sm-down {
    background-color: $gray-25;
  }

  &:active,
  &:hover {
    background-color: $gray-50;
  }
}
