:root {
  --rsbs-backdrop-bg: none;
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

.BottomDrawer {
  [data-rsbs-overlay],
  [data-rsbs-backdrop] {
    z-index: $modal-z-index + 1;
  }

  &::after {
    z-index: $modal-z-index + 1;
  }
  [data-rsbs-header] {
    padding: 0;
    box-shadow: none;
  }

  [data-rsbs-header]:before {
    top: 12px;
    background-color: $gray-100;
    width: 64px;
    height: 4px;
    border-radius: 1000px;
  }
}
