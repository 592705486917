.PaginationControl-icon {
  background-color: $white;
}

.PaginationControl-button:hover {
  .PaginationControl-icon {
    background-color: $blue-50;
  }
}

.WorkspaceCarousel-sessionContextCard {
  position: relative;
}

.WorkspaceCarousel-sessionContextCard:hover .WorkspaceCarousel-sessionContextCard-image {
  transform: scale(1.02);
}

.WorkspaceCarousel-sessionContextCard.is-active {
  transform: scale(0.99);
}

.WorkspaceCarousel-sessionContextCard,
.WorkspaceCarousel-sessionContextCard-image {
  transition: transform 200ms;
}
