@mixin size-config($size, $config) {
  .ComboBox.is-size-#{$size} {
    $font-size: map-get($config, typeahead-result-font-size);

    .ComboBox-results {
      top: map-get($config, height) + 8px;
      overflow: auto;

      &:not(.is-visible) {
        height: 0;
      }
    }

    .ComboBox-resultText {
      font-size: $font-size;
    }

    .ComboBox-resultItem {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}

.ComboBox-resultItem {
  cursor: pointer;
  position: relative;

  &.is-focused {
    background: $blue-50;
  }

  &.is-disabled {
    cursor: not-allowed;
  }

  &:hover {
    background: $blue-50;
  }

  .ComboBox-matchedText {
    font-weight: 700;
  }

  &.is-not-creatable {
    cursor: default;
    background: unset;
    background: unset;
  }
}

.ComboBox {
  position: relative;
  width: 100%;

  &.is-link {
    cursor: pointer;

    .Input {
      cursor: pointer;
      label {
        cursor: pointer;
      }

      input {
        cursor: pointer;
      }
    }
  }
}

.ComboBox-results {
  max-width: 100%;
  width: 100%;
  background: $white;
  border-radius: $border-radius;
  box-shadow: 2px 0 22px 0 rgba(87, 73, 109, 0.12);
  position: absolute;
  z-index: $header-z-index + 1;
}

.ComboBox-resultText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@each $size in xxs, xs, sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
