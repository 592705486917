.MenuButton {
  .MenuButton-divider {
    width: 1px;
  }

  &.is-secondary-and-xs {
    background: transparent;
    border-radius: 16px;
    box-shadow: 2px 0 22px rgba(87, 73, 109, 0.12);

    .MenuButton-divider {
      display: none;
    }

    .Button {
      background-color: $white;
      box-shadow: none !important;

      &:hover,
      &:active {
        &:not(&.is-disabled) {
          background-color: $blue-50 !important;
        }
      }

      span {
        color: $blue-500 !important;
      }
    }

    .MenuButton-menuArea {
      .Button {
        box-shadow: 2px 0 22px rgba(87, 73, 109, 0.12);

        .Icon.is-color-black svg .Icon-fill-color {
          fill: $blue-500 !important;
        }
      }
    }

    .MenuButton-menuArea:hover {
      .Button {
        background-color: $blue-50 !important;
      }
    }
  }

  &.is-size-lg {
    .MenuButton-divider {
      height: $field-height-lg;
    }

    .MenuButton-menu {
      top: $field-height-lg;
    }
  }

  &.is-size-md {
    .MenuButton-divider {
      height: $field-height-md;
    }

    .MenuButton-menu {
      top: $field-height-md;
    }
  }

  &.is-size-sm {
    .MenuButton-divider {
      height: $field-height-sm;
    }

    .MenuButton-menu {
      top: $field-height-sm;
    }
  }

  &.is-size-xs {
    .Button {
      height: 32px;
      padding: 8px 16px 8px 16px;
    }

    .MenuButton-divider {
      height: 32px;
    }

    .MenuButton-menu {
      top: 32px;
    }
  }

  .MenuButton-menu {
    display: none;
    width: max-content;
  }

  .MenuButton-menuArea:hover {
    .Button {
      &.primary {
        background-color: $blue-400;
      }

      &.secondary {
        background-color: $gray-300;
      }
    }

    .MenuButton-menu {
      display: block;

      .MenuButton-menuItem {
        cursor: pointer;

        &:hover {
          background-color: $blue-50;
        }
      }
    }
  }
}
