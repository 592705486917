.Playbook-Menu {
  position: absolute;
  opacity: 0;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;

  &.is-open {
    z-index: $header-z-index + 1;
    animation: playbook-menu-entrance;
    animation-fill-mode: forwards;
    animation-duration: 300ms;
  }

  &.is-closed {
    animation: playbook-menu-exit;
    animation-fill-mode: forwards;
    animation-duration: 300ms;
  }
}

@keyframes playbook-menu-entrance {
  0% {
    opacity: 0;
    transform: translateX(-24px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes playbook-menu-exit {
  0% {
    opacity: 1;
    transform: translateX(0);
    z-index: $header-z-index + 1;
  }

  100% {
    opacity: 0;
    transform: translateX(-24px);
    z-index: -1;
  }
}
