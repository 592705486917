.VerificationCode-inputContainer {
  border-radius: $border-radius;
  position: relative;
}

.VerificationCode-input {
  border: none;
  padding: unset;
  outline: none;
  border-radius: $border-radius;
  height: 100%;
  width: 100%;
  text-align: center;
  background: $white;
  border: $input-border-width solid $gray-50;

  &.has-value {
    background: $gray-50;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px $white inset !important;
    background-color: $white !important;
    background-clip: content-box !important;
  }

  &:disabled {
    opacity: 0.4;
  }

  &:focus:not(.has-error) {
    background-color: $white;
    border: 2px solid $blue-500;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 60px $white inset !important;
      background-color: $white !important;
      background-clip: content-box !important;
    }
  }

  &.has-error {
    border: solid 2px $red-700;
  }
}
