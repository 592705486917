.OfficeRequestBottomSheet {
  animation: office-request-bottom-sheet-slide-up 400ms cubic-bezier(0.55, 0.085, 0.68, 0.53) 40ms 1 normal both;
}

@keyframes office-request-bottom-sheet-slide-up {
  0% {
    transform: translateY(120px);
  }

  100% {
    transform: translateY(0);
  }
}
