@mixin size-config($size, $config) {
  .Typeahead.is-size-#{$size} {
    $font-size: map-get($config, typeahead-result-font-size);
    $height: map-get($config, typeahead-item-height);

    .Typeahead-results {
      top: map-get($config, height) + 8px;
      min-width: map-get($config, typeahead-results-min-width);
      max-height: $height * 6;
      overflow: auto;

      &:not(.is-visible) {
        height: 0;
      }
    }

    .Typeahead-resultText {
      font-size: $font-size;
    }

    .Typeahead-resultItem {
      padding-left: map-get($config, padding-x);
      height: $height;
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}

.Typeahead-resultItem {
  cursor: pointer;
  position: relative;

  &.is-focused {
    background: $blue-50;
  }

  &:hover {
    background: $blue-50;
  }

  .Typeahead-matchedText {
    font-weight: 700;
  }
}

.Typeahead {
  position: relative;
  width: 100%;
}

.Typeahead-results {
  width: 100%;
  background: $white;
  border-radius: $border-radius;
  box-shadow: 2px 0 22px 0 rgba(87, 73, 109, 0.12);
  position: absolute;
  z-index: $header-z-index + 1;
}

.Typeahead-resultText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@each $size in xxs, xs, sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}

.MultiSelectBottomSheet-matchedText {
  font-weight: 700;
}
