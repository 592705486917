.VideoPlayer-control {
  .VideoPlayer-playIcon {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    background-color: black;
    transition: width 0.3s, height 0.3s;
  }

  &:hover {
    .VideoPlayer-playIcon {
      width: 108px;
      height: 108px;
    }
  }
}
