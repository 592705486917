// Hide Google Maps info windows
.gm-style-iw,
.gm-style-iw-a,
.gm-style-iw-c,
.gm-style-iw-t {
  display: none;
}

// Hide focus border on maps
.gm-style iframe + div { border:none!important; }

.gm-bundled-control:last-child {
  :last-child {
    box-shadow: 0px 13px 23px rgba(0, 0, 0, 0.15) !important;
  }
}

.gmnoprint.gm-bundled-control {
  right: 54px !important;
  top: 14px !important;
  bottom: unset !important;
  left: unset !important;

  .gm-control-active + div {
    margin: 0 !important;
    width: 40px !important;
  }

  .gmnoprint {
    border-radius: 100px;
    overflow: hidden;

    button {
      color: $gray-900 !important;

      &:hover {
        background-color: $gray-50 !important;
      }

      img {
        width: 12px !important;
        height: 12px !important;
        display: none;

        &:nth-child(2) {
          display: inline;
        }
      }
    }
  }
}

.Map.has-center-button {
  .gmnoprint.gm-bundled-control {
    top: 66px !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.Text {
    // Override Antd
    margin-bottom: 0;
  }

  margin-block-start: 0;
  margin-block-end: 0;
}

a {
  color: unset;
  text-decoration: none;
}
