.OfficeLeadCard:hover {
  .OfficeLeadCard-borderedCard {
    box-shadow: 2px 0px 22px rgba(87, 73, 109, 0.12);
    border-color: transparent !important;
  }
}

.TourDestination {
  &:hover .TourDestination-dragHandle {
    visibility: visible;
  }

  .TourDestination-dragHandle {
    visibility: hidden;

    &.is-dragging {
      visibility: visible;
    }

    &.is-touch-device {
      visibility: visible;
    }
  }
}
