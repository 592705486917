.ModalContainer.is-full-screen {
  @each $position in 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 {
    &.is-z-index-#{$position} {
      &.is-visible {
        z-index: $modal-z-index + $position;
        opacity: 1;
        animation: fade-up 600ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      &.is-initialized-visible {
        z-index: $modal-z-index + $position;
        opacity: 1;
      }

      &.is-entering {
        z-index: $modal-z-index + $position;
        opacity: 0;
      }

      &.is-hiding {
        z-index: $modal-z-index + $position;
        opacity: 0;
        animation: fade-down 400ms cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }

  &.is-hidden {
    z-index: -1;
    opacity: 0;
  }
}

.SideDrawer-scrim {
  z-index: $modal-z-index;

  &.is-entering {
    z-index: $modal-z-index;
    opacity: 0;
  }

  &.is-visible {
    animation: fade-in 600ms cubic-bezier(0.4, 0, 0.2, 1);
    z-index: $modal-z-index;
    opacity: 1;
  }

  &.is-hiding {
    z-index: $modal-z-index;
    animation: fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.is-hidden {
    z-index: -1;
    opacity: 0;
  }
}

.SideDrawer-content {
  z-index: $modal-z-index + 1;
  &.is-entering {
    transform: translateX(100%);
  }

  &.is-visible {
    animation: modal-slide-in-from-right 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  &.is-hiding {
    animation: modal-slide-out-to-right 400ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  &.is-hidden {
    transform: translateX(100%);
    opacity: 0;
    z-index: -1;
  }
}

.ModalContainer.is-modal {
  @each $position in 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 {
    &.is-z-index-#{$position} {
      z-index: $modal-z-index + $position;
    }
  }

  &.is-entering {
    opacity: 0;
  }

  &.is-visible {
    animation: fade-in 600ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;

    .Modal-content {
      animation: fade-up 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &.is-hiding {
    opacity: 0;
    animation: fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1);

    .Modal-content {
      animation: fade-down 400ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &.is-hidden {
    z-index: -1;
    opacity: 0;
  }
}

@keyframes modal-slide-in-from-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes modal-slide-out-to-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}
