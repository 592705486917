.Link.#{$ds-class-name} {
  cursor: pointer;

  @each $color, $hex in $colorToHexMap {
    &.is-color-#{$color} {
      color: $hex;

      &.is-underlined {
        border-bottom: 2px solid $hex;
      }

      &:hover {
        color: $hex;
      }
    }
  }

  &.has-opacity {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span {
      opacity: inherit;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &:focus {
    text-decoration: none;
  }

  &.is-fullWidth {
    display: block;
    width: 100%;
  }

  &.is-fullHeight {
    height: 100%;
  }
}
