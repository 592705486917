$chatbot-desktop-max-height: 704px;

.Chatbot-threadInput:focus {
  outline: none;
}

.Chatbot-chatbotThinkingDisplayDot {
  animation: blink 1.4s infinite both;
}

.Chatbot-chatbotThinkingDisplayDot:nth-child(2) {
  animation-delay: 0.2s;
}

.Chatbot-chatbotThinkingDisplayDot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

$start-chatbot-thread-animation-duration: 300ms;

.ChatbotAskButton.animation-in-progress {
  animation-name: chatbot-ask-button-fade;
  animation-duration: $start-chatbot-thread-animation-duration;
  animation-timing-function: ease-in-out;
}

.ChatbotAskButton-animation-scrim.animation-in-progress {
  animation-name: chatbot-ask-button-scrim-expand-and-fade;
  animation-duration: $start-chatbot-thread-animation-duration;
  animation-timing-function: ease-in-out;
}

.ChatbotAskButton-text.animation-in-progress {
  animation-name: chatbot-ask-button-text-exit-left;
  animation-duration: 280ms;
  animation-timing-function: ease-in-out;
}

.ChatbotAskButton-icon.animation-in-progress {
  animation-name: chatbot-ask-button-icon-exit-right;
  animation-duration: 280ms;
  animation-timing-function: ease-in-out;
}

.ChatbotNavigationHeader.has-animation {
  animation: chatbot-nav-header-fade-down 400ms;
  animation-timing-function: ease-in-out;
}

.ChatbotHome-welcomeContainer.animate-in {
  animation-name: fade-up;
  animation-duration: 300ms;
  animation-delay: 50ms;
  animation-timing-function: ease-in-out;
}
.ChatbotButton-icon.animate-in {
  animation-name: chatbot-button-icon-rotate-in;
  animation-duration: 250ms;
  animation-timing-function: ease-in-out;
}

@keyframes chatbot-button-icon-rotate-in {
  0% {
    opacity: 0;
    transform: rotate(-75deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
}

@keyframes chatbot-nav-header-fade-down {
  0% {
    opacity: 0;
    transform: translateY(-72px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes chatbot-ask-button-fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes chatbot-ask-button-scrim-expand-and-fade {
  0% {
    // height: 64px;
    transform: scaleX(1) scaleY(1);
    background-color: $white;
    opacity: 0;
    z-index: 1;
  }

  100% {
    transform: scaleX(2) scaleY(15);
    // height: max($chatbot-desktop-max-height, 100vh);
    height: calc(max(100vh, $chatbot-desktop-max-height));
    background-color: $blue-50;
    opacity: 1;
    z-index: 1;
  }
}

@keyframes chatbot-ask-button-icon-exit-right {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateX(60px) translateY(12px);
    opacity: 0;
  }
}

@keyframes chatbot-ask-button-text-exit-left {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-60px) translateY(12px);
    opacity: 0;
  }
}
