@use 'src/ds/media';
@use 'src/ds/text-utils';

.CmsPage {
  @include media.lg {
    @each $size, $config in $heading-config-lg {
      #{$size} {
        @include text-utils.specs($config);
      }
    }
  }

  @include media.md {
    @each $size, $config in $heading-config-md {
      #{$size} {
        @include text-utils.specs($config);
      }
    }
  }

  @include media.sm {
    @each $size, $config in $heading-config-sm {
      #{$size} {
        @include text-utils.specs($config);
      }
    }
  }
}
