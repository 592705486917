.Filter-slider {
  background: none;
  width: 100%;
  position: absolute;
  z-index: 0;

  &.is-z-index-above {
    z-index: 1;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    background: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjggMjgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeD0iMSIgeT0iMSIgd2lkdGg9IjI2IiBoZWlnaHQ9IjI2IiByeD0iMTMiIGZpbGw9IiMwMDc4RkYiLz48cGF0aCBkPSJNMTEgMTBWMTgiIHN0cm9rZT0id2hpdGUiLz48cGF0aCBkPSJNMTQgMTBWMTgiIHN0cm9rZT0id2hpdGUiLz48cGF0aCBkPSJNMTcgMTBWMTgiIHN0cm9rZT0id2hpdGUiLz48cmVjdCB4PSIxIiB5PSIxIiB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHJ4PSIxMyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+');
    width: 28px;
    height: 28px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
    border-radius: 50%;
  }
}
