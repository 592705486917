.Cart-modalContent {
  animation-name: cart-modal-content-entrance-right;

  &.is-entrance-left {
    animation-name: cart-modal-content-entrance-left;
  }

  animation-duration: 150ms;
}

@keyframes cart-modal-content-entrance-right {
  0% {
    transform: translateX(24px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes cart-modal-content-entrance-left {
  0% {
    transform: translateX(-24px);
  }

  100% {
    transform: translateX(0);
  }
}
