.Carousel {
  user-select: none;
  position: relative;

  &.is-overflow-hidden {
    overflow: hidden;
  }

  img {
    user-select: none;
    pointer-events: none;
  }
}

.Carousel-button {
  &:hover .Carousel-control {
    background-color: $blue-50;
  }

  &:focus-visible .Carousel-control {
    background-color: $blue-50;
    border: 1px solid $blue-500;
  }

  &:focus:not(:focus-visible) .Carousel-control {
    background-color: none;
    border: none;
  }
}