@mixin size-config($size, $config) {
  .Toggle.is-size-#{$size} {
    $font-size: map-get($config, font-size);
    $padding-y: map-get($config, toggle-padding-y);

    font-size: $font-size;
    padding-top: $padding-y;
    padding-bottom: $padding-y;
    height: map-get($config, height);

    &.has-border {
      padding-left: map-get($config, inner-padding-x);
      padding-right: map-get($config, inner-padding-x);
    }

    line-height: map-get($config, line-height);

    .Toggle-label {
      margin-right: map-get($config, inner-padding-x);
    }
  }

  .Toggle-control.is-size-#{$size} {
    $control-width: map-get($config, toggle-width);
    $control-height: map-get($config, toggle-height);
    $padding-x: map-get($config, toggle-padding-x);
    $padding-y: map-get($config, toggle-padding-y);
    $circle-dimension: $control-height - $padding-y * 2;

    .Toggle-slider {
      width: $control-width;
      height: $control-height;
    }

    .Toggle-circle {
      right: $control-width - $padding-x - $circle-dimension;
      top: $padding-y;
      width: $circle-dimension;
      height: $circle-dimension;
    }

    .Toggle-spinner {
      bottom: map-get($config, $spinner-size-xs);
      right: $padding-x;
    }

    &.is-checked {
      .Toggle-circle {
        right: $padding-x;
      }
    }
  }
}

.Toggle-spinner {
  position: absolute;
  opacity: 0;
}

.Toggle {
  width: 100%;

  &.has-border {
    border: 1px solid $gray-50;
    border-radius: 12px;
  }
}

.Toggle-slider {
  border-radius: 300px;
  background-color: $gray-50;
  position: relative;
  display: inline-block;
  transition: background-color 450ms ease-in-out;
}

.Toggle-circle {
  border-radius: 50%;
  background-color: $white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transition: right 450ms ease-in-out;
}

.Toggle-control {
  display: inline-flex;

  &.is-checked {
    .Toggle-slider {
      justify-content: flex-end;

      &.is-color-blue-500 {
        background-color: $blue-500;
      }
    }
  }

  &.is-disabled {
    opacity: 0.4;
    cursor: default;
  }

  &.has-spinner {
    .Toggle-spinner {
      opacity: 1;
    }

    .Toggle-slider {
      opacity: 0;
    }
  }
}

@each $size in sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
