@use 'src/ds/text-utils';

.LocationSearch-resultItem {
  @include text-utils.specs($text-body-xs);
  padding: 14px 24px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  &.is-focused {
    background: $blue-50;
  }

  &:hover {
    background: $blue-50;
  }

  .LocationSearch-matchedText {
    font-weight: 700;
  }
}

.LocationSearch {
  position: relative;
  width: 100%;
}

.LocationSearch-resultText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
}

.MultiSelectBottomSheet-matchedText {
  font-weight: 700;
}

.LocationSearch-results {
  width: 100%;
  background: $white;
  position: absolute;
  top: 8px;

  &:not(.is-bottom-sheet) {
    z-index: $header-z-index + 1;
    padding: 8px 0;
    border-radius: $border-radius;
    box-shadow: 2px 0 22px 0 rgba(87, 73, 109, 0.12);
  }

  &.no-results {
    padding: 0;
  }

  &:not(.is-visible) {
    height: 0;
    overflow: hidden;
  }
}

.LocationSearch-matchedText {
  font-weight: 700;
}

.OfficeSearch-workspaceDetailsCard {
  opacity: 0;

  &.is-visible {
    animation-name: office-search-workspace-details-card-entrance;
    animation-duration: 150ms;
    animation-fill-mode: forwards;
  }
}

@keyframes office-search-workspace-details-card-entrance {
  0% {
    opacity: 0;
    transform: translateX(-12px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.TourCart {
  opacity: 0;
  position: absolute;
  top: 44px;
  right: 0;
  display: none;

  &.is-visible {
    animation-name: office-search-entrance-from-top;
    animation-duration: 150ms;
    animation-fill-mode: forwards;
    display: block;
  }
}

@keyframes office-search-entrance-from-top {
  0% {
    opacity: 0;
    transform: translateY(-12px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes office-search-entrance-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(12px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.TourCart-destination {
  .TourCart-removeDestinationButton {
    opacity: 0;
  }

  &:hover {
    .TourCart-removeDestinationButton {
      opacity: 1;
    }
  }
}

.OfficeSearch-saveFilters {
  top: 24px;
  right: 40px;
}

.OfficeSearch-saveFilters,
.OfficeSearch-saveFiltersMobile {
  opacity: 0;
  position: absolute;
  display: none;

  &.is-visible {
    animation-name: save-filters-entrance;
    animation-duration: 150ms;
    animation-fill-mode: forwards;
    display: flex;
  }
}

@keyframes save-filters-entrance {
  0% {
    opacity: 0;
    transform: translateY(12px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.OfficeRequest-searchMap {
  .gmnoprint.gm-bundled-control {
    right: 54px !important;
    top: unset !important;
    bottom: 100px !important;
  }
}

.HeartButton {
  &:hover {
    background-color: $gray-50;
  }
}

.SimpleHeartButtonMobile {
  &:active {
    background: $gray-50;
  }
}

.SimpleHeartButton-hoverIcon {
  display: inline-flex;
  opacity: 0;
}

.SimpleHeartButton {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border-radius: 100%;
  color: $white;

  &:hover {
    box-shadow: 2px 0px 22px 0px rgba(87, 73, 109, 0.12);

    .SimpleHeartButton-hoverIcon {
      opacity: 0.4;
    }
  }
}

.OfficeRequest-locationBar {
  height: 64px;
  .OfficeRequest-locationBar-button {
    min-height: 64px;
    border-radius: 1000px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .OfficeRequest-locationBar-divider {
    border-left: 1px $gray-50 solid;
  }
  &:hover {
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
    .OfficeRequest-locationBar-divider {
      border-left: 1px $white solid;
    }
  }
}

.RecommendedOfficeCard,
.OfficeRequest-workspaceListCard {
  .CarouselArrow {
    opacity: 0;
  }

  &:hover {
    .CarouselArrow {
      opacity: 0.8;
    }

    .IconButton {
      opacity: 0.8;
    }
  }
}

.MobileWorkspaceMapCard {
  display: none;

  &.is-visible {
    animation-name: office-search-entrance-from-bottom;
    animation-duration: 150ms;
    animation-fill-mode: forwards;
    display: flex;
  }
}

.WorkspaceTourButton {
  .WorkspaceTourButton-requested {
    opacity: 1;
  }
  .WorkspaceTourButton-cancel {
    opacity: 0;
  }

  &:hover.is-cancelable,
  .is-removing {
    .WorkspaceTourButton-requested {
      opacity: 0;
    }
    .WorkspaceTourButton-cancel {
      opacity: 1;
    }
  }
}

.OfficeRequestWorkspaceModal-imageCarousel {
  .CarouselArrow {
    opacity: 0;
  }

  &:hover {
    .CarouselArrow {
      opacity: 0.8;
    }
  }
}
