.IconButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.is-size-xs {
    width: $icon-button-size-xs;
    height: $icon-button-size-xs;
  }

  &.is-size-sm {
    width: $icon-button-size-sm;
    height: $icon-button-size-sm;
  }

  &.is-size-md {
    width: $icon-button-size-md;
    height: $icon-button-size-md;
  }

  &.is-size-lg {
    width: $icon-button-size-lg;
    height: $icon-button-size-lg;
  }

  &.is-disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &.is-noBackground {
    &:hover {
      background: rgba($gray-900, 0.1);
    }

    &:active {
      background: $gray-200;
    }
  }

  &.has-shadow {
    box-shadow: 2px 0 22px 0 #57496d1f;
  }
}
