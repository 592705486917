@mixin size-config($size, $config) {
  .NumberStepper.#{$ds-class-name}.is-size-#{$size} {
    $font-size: map-get($config, font-size);
    $height: map-get($config, height);

    font-size: $font-size;
    height: $height;
    width: 100%;
    padding-left: map-get($config, inner-padding-x);
    padding-right: map-get($config, inner-padding-x);
    line-height: map-get($config, line-height);
    border: 1px solid $gray-50;
    border-radius: 12px;
  }
}

.NumberStepperControl {
  user-select: none;
  -webkit-user-select: none;
}

@each $size in sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
