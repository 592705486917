@use '../inputs';
@use '../media';

@mixin size-config($size, $config) {
  $font-size: map-get($config, font-size);
  $line-height: map-get($config, line-height);
  $height: map-get($config, height);
  $small-label-line-height: map-get($config, small-label-line-height);
  $small-label-font-size: map-get($config, small-label-font-size);
  $padding-left: map-get($config, inner-padding-x);
  $padding-right: map-get($config, inner-padding-x);
  $small-label-top: ($height - ($small-label-line-height + $line-height)) / 2;
  $clear-size: map-get($config, clear-size);
  $clear-click-target: 36px;
  $clear-right: $padding-right - ($clear-click-target - $clear-size) / 2;
  $clear-padding: ($clear-click-target - $clear-size) / 2;

  .#{$ds-class-name}.Input-outerContainer.is-size-#{$size} {
    &.is-inline {
      display: flex;
      align-items: center;

      label {
        font-size: $font-size;
        line-height: $line-height;
        height: $height;
      }
    }
  }

  .#{$ds-class-name}.Input.is-size-#{$size} {
    font-size: $font-size;
    height: $height;

    &:hover:not(.is-read-only) {
      .Input-input {
        background-color: $gray-50;
      }

      .Input-closeButton {
        opacity: 1;
      }
    }

    &.is-inline {
      flex-grow: 1;
      .Input-suffix {
        right: map-get($config, inner-padding-x) + 24px;
      }
    }

    .Input-label {
      left: map-get($config, inner-padding-x);
      font-size: $font-size;
      line-height: $font-size;
      top: ($height - $font-size) / 2;
    }

    .Input-prefix {
      position: absolute;
      z-index: -1;
      opacity: 0;
      font-size: $font-size;
      left: map-get($config, inner-padding-x);
      top: $small-label-top + $small-label-line-height;
    }

    .Input-textPrefix,
    .Input-suffix {
      position: absolute;
      font-size: $font-size;
      color: $gray-600;
      line-height: $line-height;
      top: ($height - $line-height) / 2;
    }

    .Input-textPrefix {
      left: map-get($config, inner-padding-x);
      opacity: 1;
      transition: opacity 0.2s;
    }

    .Input-suffix {
      right: map-get($config, inner-padding-x);
    }

    .Input-icon {
      top: ($height - map-get($config, icon-size)) / 2;
      left: map-get($config, padding-x);
    }

    .Input-image {
      top: ($height - map-get($config, image-size)) / 2;
      left: map-get($config, padding-x);

      img {
        width: map-get($config, image-size);
        height: map-get($config, image-size);
        object-fit: cover;
      }
    }

    .Input-closeButton {
      position: absolute;
      right: $clear-right;
      top: ($height - $clear-click-target) / 2;
      display: inline-flex;
      z-index: 3;
      padding: $clear-padding;
    }

    .Input-closeSvg {
      display: inline-flex;

      svg {
        width: $clear-size;
        min-width: $clear-size;
        height: $clear-size;
      }
    }

    &.is-read-only {
      .Input-input {
        pointer-events: none;
      }
    }

    .Input-input {
      &:focus:not(.has-small-label),
      &.is-focused:not(.has-small-label),
      &.has-value:not(.has-small-label) {
        ~ .Input-label {
          opacity: 0;
        }
      }

      &:focus.has-small-label,
      &.is-focused.has-small-label,
      &.has-value.has-small-label {
        ~ .Input-label {
          top: $small-label-top;
          font-size: $small-label-font-size;
          line-height: $small-label-line-height;
          font-weight: 600;
          color: $blue-500;
          opacity: 1;
        }

        ~ .Input-prefix {
          opacity: 1;
          z-index: 1;
        }

        padding-top: $small-label-top - $input-focus-border-width + $small-label-line-height;
        padding-bottom: $small-label-top;
      }

      &:focus:not(.has-error),
      &.is-focused:not(.has-error) {
        background-color: $white;
        padding-left: $padding-left - $input-focus-border-width;
        padding-right: $padding-right - $input-focus-border-width;

        &.has-clear-button {
          padding-right: $clear-click-target + $clear-right + 2px;
        }
      }

      &.has-small-label,
      :not(.has-value) {
        ~ .Input-textPrefix,
        ~ .Input-suffix {
          top: $small-label-top + $small-label-line-height;
        }
      }

      &:focus,
      &.is-focused {
        ~ .Input-closeButton {
          opacity: 1;
        }
      }

      &:not(:focus):not(.has-value) {
        ~ .Input-textPrefix,
        ~ .Input-suffix {
          opacity: 0;
        }
      }

      &.has-error:not(.is-disabled).has-small-label {
        padding-top: $small-label-top - $input-border-width + $small-label-line-height;
        padding-bottom: $small-label-top;

        &:not(.has-icon),
        &:not(.has-image) {
          padding-left: $padding-left - $input-error-border-width;
          padding-left: $padding-right - $input-error-border-width;
        }

        ~ .Input-label {
          color: $red-700;
        }
      }

      &.is-disabled {
        cursor: not-allowed;
        color: rgba($gray-900, 0.5);
        background-color: $gray-25;

        ~ .Input-prefix {
          opacity: 0.5;
        }

        ~ .Input-label {
          color: rgba($gray-900, 0.5);
        }
      }

      &.has-icon {
        padding-left: map-get($config, inner-padding-x-with-icon) - $input-border-width;

        ~ .Input-label {
          left: map-get($config, inner-padding-x-with-icon);
        }

        &.has-prefix {
          ~ .Input-textPrefix {
            left: map-get($config, inner-padding-x-with-icon);
          }
        }

        &:focus:not(.has-error),
        &.is-focused:not(.has-error) {
          padding-left: map-get($config, inner-padding-x-with-icon) - $input-focus-border-width;

          ~ .Input-icon svg .Icon-fill-color {
            fill: $blue-500;
          }
        }
      }

      &.has-image {
        padding-left: map-get($config, inner-padding-x-with-image) - $input-border-width;

        ~ .Input-label {
          left: map-get($config, inner-padding-x-with-image);
        }

        &.has-prefix {
          ~ .Input-textPrefix {
            left: map-get($config, inner-padding-x-with-image);
          }
        }

        &:focus:not(.has-error),
        &.is-focused:not(.has-error) {
          padding-left: map-get($config, inner-padding-x-with-image) - $input-focus-border-width;
        }
      }

      &.has-clear-button {
        padding-right: $clear-click-target + $clear-right + 2px;
      }

      font-size: $font-size;
      padding-left: $padding-left - $input-border-width;
      padding-right: $padding-right - $input-border-width;
      line-height: $line-height;
    }
  }

  .Input-error.is-size-#{$size} {
    font-size: map-get($config, input-error-font-size);
    line-height: 16px;
    margin-top: 4px;
    color: $red-700;
    white-space: wrap;
  }
}

.Input.#{$ds-class-name} {
  position: relative;

  &.has-value,
  &.has-error,
  &.is-disabled {
    .Input-prefix {
      opacity: 1;
    }
  }

  .Input-prefix {
    opacity: 0.5;
  }
}

input.Input-input.#{$ds-class-name} {
  @include inputs.autofill-style;

  appearance: none;
  border-style: none;
  padding: unset;
  height: 100%;
  width: 100%;
  border-radius: $input-border-radius;
  outline: none;
  background-color: $white;

  &.is-inline {
    border: $input-border-width solid transparent;
  }

  &:not(.is-inline) {
    border: $input-border-width solid $gray-50;
    &::placeholder {
      color: $gray-900;
      opacity: 0;
    }
  }

  &.has-small-label {
    ~ .Input-label {
      transition: font-size, opacity, top, 0.2s;
    }
  }

  &:focus::placeholder {
    opacity: 0.5;
  }

  &.is-inline {
    ~ .Input-inlineEditIcon {
      position: absolute;
      align-items: center;
      display: flex;
      right: 12px;
      top: 0;
      height: 100%;
      opacity: 0;
    }

    &:hover:not(:focus) {
      ~ .Input-inlineEditIcon {
        opacity: 1;
      }
    }
  }

  &:focus:not(.has-error),
  &.is-focused:not(.has-error) {
    border: $input-focus-border-width solid $blue-500;
  }

  &.has-error:not(.is-disabled) {
    background-color: $white;
    border: $input-error-border-width solid $red-700;

    & ~ .Input-label {
      color: $red-700;
      opacity: 1;
    }
  }
}

.Input-label {
  position: absolute;
}

.Input-icon,
.Input-image {
  position: absolute;
}

.Input-innerContainer {
  flex-grow: 1;
}

@each $size in xxs, xs, sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
