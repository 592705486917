.AvatarUpdate {
  .Avatar,
  .AvatarUpdate-editButton {
    background-color: $gray-25;

    &:hover {
      background-color: $gray-50;
    }
    &:active {
      background-color: $gray-100;
    }
  }
  .AvatarUpdate-emptyAvatar {
    cursor: pointer;
    position: relative;
  }
}
