@use './media';

@mixin size-config($size, $config) {
  .EllipsisMenu.#{$ds-class-name}.is-size-#{$size} {
    // TODO: add size configuration?
  }
}

.EllipsisMenu {
  .EllipsisMenu-menuItem {
    @each $color in 'blue-50', 'gray-50' {
      &:hover.is-hover-color-#{$color} {
        background-color: map-get($colorToHexMap, $color);
      }
    }
  }
}

@each $size in sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
