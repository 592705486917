@use 'src/ds/media';

body {
  font-family: Inter, sans-serif;
  line-height: normal;
  font-size: unset;
  overscroll-behavior-y: none;
  margin: 0;
  color: $gray-900;
  height: 100%;

  &.is-scroll-locked {
    overflow: hidden;
    inset: 0;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include media.sm {
    min-height: stretch;
  }

  &.is-scroll-locked {
    @include media.sm {
      overflow: hidden;
      height: stretch;
      max-height: stretch;
    }
  }
}

html {
  box-sizing: border-box;
  overscroll-behavior-y: none;
  -webkit-text-size-adjust: 100%;
}

img {
  width: 100%;
  display: block;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

p {
  margin: 0;
}

table {
  border-spacing: 0;

  tr {
    border-spacing: 0;
  }
}

ul {
  margin: 0;
  padding-left: 1em;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

input::placeholder {
  color: $gray-400;
}

input {
  font: unset;
  appearance: none;
  color: $gray-900;
}

div:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: unset;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}
