.TextButton {
  padding: 0;
  background: none;
  display: inline-flex;

  span {
    cursor: pointer;
  }

  @each $size in sm, md, lg {
    &.is-size-#{$size} {
      $padding-size: map-get(map-get($field-size-config, $size), text-button-padding);

      &.has-paddingX {
        padding-left: $padding-size;
        padding-right: $padding-size;
      }

      &.has-paddingY {
        padding-top: $padding-size;
        padding-bottom: $padding-size;
      }
    }
  }

  &:hover {
    &.has-hover-opacity {
      opacity: 0.8;

      span {
        opacity: 0.8;
      }
    }
  }

  &.is-fullWidth {
    width: 100%;
  }

  &.is-disabled {
    opacity: 0.4;
  }
}
