@mixin size-config($size, $config) {
  .LocationInput.is-size-#{$size} {
    $font-size: map-get($config, typeahead-result-font-size);
    $result-item-height: map-get($config, typeahead-item-height);

    .LocationInput-results {
      top: map-get($config, height) + 8px;
    }

    .LocationInput-resultText {
      font-size: $font-size;
      padding-right: map-get($config, inner-padding-x);
    }

    .LocationInput-resultItem {
      padding-left: map-get($config, inner-padding-x);
      height: $result-item-height;
      align-items: center;
      display: inline-flex;
      width: 100%;
    }

    .LocationInput-resultItemIcon {
      left: map-get($config, padding-x) + (map-get($config, icon-size) - $icon-size-xs) / 2;
      top: ($result-item-height - $icon-size-xs) / 2;
    }
  }
}

.LocationInput-resultItem {
  cursor: pointer;
  position: relative;

  &.is-focused {
    background: $blue-50;
  }

  &:hover {
    background: $blue-50;
  }
}

.LocationInput {
  position: relative;
}

.LocationInput-results {
  width: 100%;
  background: $white;
  border-radius: $border-radius;
  box-shadow: 2px 0 22px 0 rgba(87, 73, 109, 0.12);
  position: absolute;
  z-index: $header-z-index + 1;
}

.LocationInput-resultText {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.LocationInput-resultItemIcon {
  position: absolute;
  display: inline-flex;
}

.LocationInput-poweredByGoogleContainer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.LocationInput-poweredByGoogleImage {
  width: 120px;
}

@each $size in sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
