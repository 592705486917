@mixin size-config($size, $config) {
  .DatePicker.is-size-#{$size} {
    $font-size: map-get($config, font-size);
    $height: map-get($config, height);
    $small-label-line-height: map-get($config, small-label-line-height);
    $small-label-font-size: map-get($config, small-label-font-size);
    $line-height: map-get($config, line-height);
    $small-label-top: ($height - ($small-label-line-height + $line-height)) / 2;
    $clear-size: map-get($config, clear-size);
    $padding-right: map-get($config, inner-padding-x);
    $clear-size: map-get($config, clear-size);
    $clear-click-target: 36px;
    $clear-right: $padding-right - ($clear-click-target - $clear-size) / 2;
    $clear-padding: ($clear-click-target - $clear-size) / 2;

    font-size: $font-size;

    .DatePicker-picker {
      font-size: $font-size;
    }

    .DatePicker-value,
    .DatePicker-label,
    .DatePicker-placeholder {
      font-size: $font-size;
      line-height: $line-height;
    }

    .DatePicker-label,
    .DatePicker-placeholder {
      top: ($height - $line-height) / 2;
    }

    .DatePicker-value,
    .DatePicker-label,
    .DatePicker-placeholder {
      left: map-get($config, inner-padding-x);
    }

    &.has-icon {
      .DatePicker-value,
      .DatePicker-label,
      .DatePicker-placeholder {
        left: map-get($config, inner-padding-x-with-icon);
      }
    }

    .DatePicker-closeButton {
      display: inline-flex;
    }

    .DatePicker-icon {
      svg {
        transition: fill 0.2s;
      }
    }

    &.is-marketing-type {
      .DatePicker-closeButton {
        margin-left: 12px;
      }

      .DatePicker-icon {
        margin-right: 12px;
      }
    }

    &.is-read-only {
      .DatePicker-value {
        pointer-events: all;
      }
    }

    &.is-default-type {
      .DatePicker-picker {
        height: $height;
      }

      .DatePicker-icon {
        top: ($height - map-get($config, icon-size)) / 2;
        left: map-get($config, padding-x);
      }

      .DatePicker-value {
        top: ($height - $line-height) / 2;

        &.has-small-label {
          bottom: $small-label-top;
          top: unset;
        }
      }
    }

    .DatePicker-closeButton {
      position: absolute;
      right: $clear-right;
      top: ($height - $clear-click-target) / 2;
      display: inline-flex;
      z-index: 3;
      padding: $clear-padding;
    }

    .DatePicker-closeSvg {
      display: inline-flex;

      svg {
        width: $clear-size;
        min-width: $clear-size;
        height: $clear-size;
      }
    }

    &.is-marketing-type {
      .DatePicker-picker {
        display: flex;
        justify-content: center;
      }
    }

    &.is-button-control {
      .DatePicker-picker {
        padding-left: map-get($config, inner-padding-x);
        padding-right: map-get($config, inner-padding-x);
        line-height: map-get($config, line-height);
      }
    }

    &.is-filled,
    &.is-open {
      .DatePicker-label {
        font-size: $small-label-font-size;
        line-height: $small-label-line-height;
        top: $small-label-top;
      }
    }
  }
}

.DatePicker-picker.#{$ds-class-name} {
  width: 100%;

  &:not(.is-read-only) {
    cursor: pointer;

    &:hover,
    &:active {
      &:not(&.is-disabled) {
        &.primary {
          background-color: $blue-400;
        }

        &.secondary {
          background-color: $gray-300;
        }

        &.secondaryOutline {
          background-color: $gray-100;
        }
      }
    }
  }

  border-radius: $border-radius;
}

.DatePicker {
  position: relative;
  min-width: 180px;

  &.is-panel:not(.is-button-control).is-default-type.is-fixed-width {
    // width: $calendar-width;
  }

  &.is-marketing-type:not(.is-bottom-sheet) {
    min-width: 280px;
  }

  .DatePicker-icon {
    pointer-events: none;
  }

  .DatePicker-value {
    pointer-events: none;
    white-space: nowrap;
    color: $black;
  }

  .DatePicker-label {
    position: absolute;
    transition: color, font-size, opacity, top, 0.2s;
    font-weight: 300;
  }

  .DatePicker-closeButton {
    pointer-events: none;
  }

  &.is-default-type {
    .DatePicker-placeholder {
      position: absolute;
    }

    .DatePicker-value {
      position: absolute;
    }

    .DatePicker-icon {
      position: absolute;
    }

    .DatePicker-closeButton {
      position: absolute;
    }
  }

  &.is-marketing-type {
    .DatePicker-placeholder {
      opacity: 0.6;
      min-width: 260px;
      line-height: 36px !important;
      font-size: 24px !important;
    }

    &.is-bottom-sheet {
      .DatePicker-placeholder {
        opacity: 0.6;
        min-width: 200px;
        line-height: 24px !important;
        font-size: 16px !important;
      }
    }
  }

  .DatePicker-buttonText {
    opacity: 0.6;
    cursor: pointer;
  }

  &.is-filled {
    .DatePicker-buttonText {
      opacity: 1;
    }
  }

  &.is-filled,
  &.is-open {
    .DatePicker-label {
      opacity: 1;
      font-weight: 600;
      color: $blue-500;
    }
  }

  &.is-open {
    .DatePicker-placeholder {
      opacity: 0.4;
    }
  }

  &.is-button-control {
    .DatePicker-picker {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.is-disabled {
    .DatePicker-picker {
      opacity: 0.6;
      cursor: not-allowed;
    }

    .DatePicker-closeButton {
      opacity: 0.6;
    }
  }
}

@each $size in xxs, xs, sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
