@use 'src/ds/media';

.HomeSearchInput-container {
  width: 100%;
}

.HomeSearchInput-locationResults {
  animation-name: slideInHome;
  animation-duration: 300ms;
  top: 70px;
}

@keyframes slideInHome {
  from {
    top: 58px;
    opacity: 0;
  }

  to {
    top: 70px;
    opacity: 1;
  }
}

.Home-browseLocation {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53%, rgba(0, 0, 0, 0.83) 100%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }

  @include media.sm {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 54%, rgba(0, 0, 0, 0.8) 89%);
  }
}

.Home-insightLink {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 100%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
}

.Home-valueProps,
.Home-testimonialsCarousel {
  .Home-textCarousel {
    .swiper-slide-active {
      animation-name: home-carousel-text-slide-in;
      animation-duration: 300ms;
      animation-timing-function: ease-in;
    }

    .swiper-slide-prev,
    .swiper-slide-next {
      animation-name: home-carousel-text-slide-out;
      animation-duration: 100ms;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }

  .Home-imgCarousel {
    .swiper-slide-active {
      animation-name: home-carousel-img-slide-in;
      animation-duration: 300ms;
      animation-timing-function: ease-in;
    }

    .swiper-slide-prev,
    .swiper-slide-next {
      animation-duration: home-carousel-img-slide-out;
      animation-duration: 175ms;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }
}

.Home-valueProps-tab {
  &.can-animate {
    transform-origin: 0% 50%;
    transform: scaleX(0);

    &.is-active {
      animation-name: homes-tab-progress;
      animation-duration: 5000ms;
      animation-timing-function: linear;
    }
  }

  &.is-manual {
    opacity: 0;

    &.is-active {
      animation: homes-tab-fade-in;
      animation-fill-mode: forwards;
      animation-duration: 175ms;
    }

    &.has-been-selected:not(.is-active) {
      animation: homes-tab-fade-out;
      animation-fill-mode: forwards;
      animation-duration: 175ms;
    }
  }
}

@keyframes home-carousel-text-slide-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes home-carousel-text-slide-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes home-carousel-img-slide-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes home-carousel-img-slide-out {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes homes-tab-progress {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes homes-tab-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes homes-tab-fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.Home-marqueeContainer {
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );

  .Home-logoMarqueeTop {
    flex-shrink: 0;
  }

  .Home-logoMarqueeBottom {
    flex-shrink: 0;
  }

  &.is-xs {
    .Home-logoMarqueeTop {
      flex-shrink: 0;
    }

    .Home-logoMarqueeBottom {
      flex-shrink: 0;
    }
  }
}
