@use 'src/ds/media';
@use 'src/ds/text-utils';

$component-vertical-spacing: 24px;

.CmsContent {
  @include media.lg {
    p,
    li,
    figcaption,
    td {
      @include text-utils.specs(map-get($text-config-lg, body1));
    }

    blockquote {
      p {
        @include text-utils.specs(map-get($heading-config-lg, h5));
      }
    }

    @each $size, $config in $heading-config-lg {
      #{$size} {
        @include text-utils.specs($config);
      }
    }
  }

  @include media.md {
    p,
    li,
    figcaption,
    td {
      @include text-utils.specs(map-get($text-config-md, body1));
    }

    blockquote {
      p {
        @include text-utils.specs(map-get($heading-config-md, h5));
      }
    }

    @each $size, $config in $heading-config-md {
      #{$size} {
        @include text-utils.specs($config);
      }
    }
  }

  @include media.sm {
    p,
    li,
    figcaption,
    td {
      @include text-utils.specs(map-get($text-config-sm, body1));
    }

    blockquote {
      p {
        @include text-utils.specs(map-get($heading-config-sm, h5));
      }
    }

    @each $size, $config in $heading-config-sm {
      #{$size} {
        @include text-utils.specs($config);
      }
    }
  }

  a {
    color: $blue-500;

    &:hover {
      opacity: 0.8;
    }
  }

  img {
    margin-top: $component-vertical-spacing;
  }

  iframe {
    width: 100%;
    max-height: 100vw;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: $component-vertical-spacing;
  }

  figcaption {
    margin-top: 0;
    color: $gray-600;
    font-weight: 700;
  }

  blockquote {
    background-color: $blue-50;
    border-radius: 20px;
    margin-top: $component-vertical-spacing;
    margin-bottom: $component-vertical-spacing;
    padding: 16px 32px 16px 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 36px;

    p {
      height: 100%;
      color: $blue-500;
      font-weight: 800;
      margin-top: 0;
      margin-bottom: 0;
      border-left: 4px solid $blue-500;
      padding-left: 16px;
    }
  }

  ul,
  ol {
    margin-top: $component-vertical-spacing;
    margin-bottom: 0;
  }

  table {
    td {
      padding: 8px;
      text-align: center;
      vertical-align: top;
    }

    tr:first-child td {
      background-color: $blue-500;
      color: $white;
      font-weight: 700;
    }
  }
}
