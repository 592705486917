@mixin size-config($size, $config) {
  .TextArea.#{$ds-class-name}.is-size-#{$size} {
    $small-label-line-height: map-get($config, small-label-line-height);
    $small-label-font-size: map-get($config, small-label-font-size);
    $line-height: map-get($config, line-height);
    $font-size: map-get($config, font-size);
    $height: map-get($config, height);
    $padding-left: map-get($config, inner-padding-x);
    $padding-right: map-get($config, inner-padding-x);
    $small-label-top: ($height - ($small-label-line-height + $line-height)) / 2;

    .TextArea-textarea {
      &:focus.has-small-label,
      &.has-value.has-small-label {
        ~ .TextArea-label {
          top: $small-label-top;
          font-size: $small-label-font-size;
          line-height: $small-label-line-height;
          font-weight: 600;
          color: $blue-500;
          opacity: 1;
        }
      }

      &:focus:not(.has-small-label),
      &.has-value:not(.has-small-label) {
        ~ .TextArea-label {
          opacity: 0;
        }
      }

      &:focus:not(.has-error) {
        padding-left: $padding-left - $input-focus-border-width;
        padding-right: $padding-right - $input-focus-border-width;

        &.has-small-label {
          padding-top: $small-label-top - $input-focus-border-width + $small-label-line-height;
        }
        padding-bottom: $small-label-top - $input-focus-border-width;

        ~ .TextArea-labelBackground {
          top: $input-focus-border-width;
        }
      }

      &:not(.has-small-label) {
        ~ .TextArea-labelBackground {
          display: none;
        }  
      }

      &.has-small-label {
        padding-top: $small-label-top - $input-border-width + $small-label-line-height;
      }
      &:not(.has-small-label) {
        padding-top: ($height - $font-size) / 2 - ($line-height - $font-size) / 2 - $input-border-width;
      }
      padding-bottom: $small-label-top - $input-border-width;
      padding-left: $padding-left - $input-border-width;
      padding-right: $padding-right - $input-border-width;

      &.has-max-height {
        max-height: 504px;
      }

      min-width: 96px;

      line-height: $line-height;
      font-size: $font-size;

      &.is-disabled {
        cursor: not-allowed;
        color: rgba($gray-900, 0.5);
        background-color: $gray-25;

        ~ .TextArea-label {
          color: rgba($gray-900, 0.5);
        }
      }

      &.has-error {
        ~ .TextArea-labelBackground {
          top: $input-error-border-width;
        }

        ~ .TextArea-label {
          color: $red-700;
        }
      }
    }

    .TextArea-label {
      left: $padding-left;
      line-height: $font-size;
      font-size: $font-size;
      top: ($height - $font-size) / 2;
    }

    .TextArea-labelBackground {
      top: $input-border-width;
      left: $padding-left;
      width: calc(100% - #{map-get($config, inner-padding-x)} * 2);
      height: map-get($config, small-label-line-height) + $small-label-top;
    }
  }
}

textarea.TextArea-textarea.#{$ds-class-name} {
  border-style: none;
  padding: unset;
  height: 100%;
  width: 100%;
  border-radius: $input-border-radius;
  outline: none;
  background-color: $white;
  border: $input-border-width solid $gray-50;
  position: relative;

  &::placeholder {
    color: $black;
    opacity: 0;
  }

  &:hover {
    background-color: $gray-50;

    & ~ .TextArea-labelBackground {
      background: $gray-50;
    }
  }

  &:focus:not(.has-error) {
    background-color: $white;
    border: $input-focus-border-width solid $blue-500;

    & ~ .TextArea-prefix {
      opacity: 0.5;
    }

    & ~ .TextArea-label {
      opacity: 1;
      font-weight: 600;
      color: $blue-500;
    }

    & ~ .TextArea-labelBackground {
      background: $white;
    }

    &::placeholder {
      opacity: 0.5;
    }
  }

  &.has-error {
    background-color: $white;
    border: $input-error-border-width solid $red-700;

    & ~ .TexArea-label {
      color: $red-700;
    }

    ~ .TextArea-labelBackground {
      border: none;
      background: $white;
    }
  }
}

.TextArea.#{$ds-class-name} {
  position: relative;
}

.TextArea-label {
  position: absolute;
  transition: color, font-size, opacity, top, 0.2s;

  &.has-value {
    opacity: 1;
    font-weight: 600;
    color: $blue-500;
    transition: none;
  }
}

.TextArea-labelBackground {
  position: absolute;
  background: $white;
}

.TextArea-textarea {
  font-family: $font-family;
  display: block;
}

@each $size in xxs, xs, sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
