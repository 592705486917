@use 'src/ds/media';

.NavigationMenu-container {
  transition: width 200ms cubic-bezier(0, 0, 0.16, 0.96);
}

.NavigationMenu-menuSection,
.NavigationMenu-ctaSection,
.NavigationMenu-locationSection {
  transition: transform 0.35s cubic-bezier(0, 0, 0.16, 0.96);
}

.Navigation-mobileMenuContainer {
  animation-name: fadeIn;
  animation-duration: 600ms;
  animation-timing-function: transform 0.15s cubic-bezier(0, 0, 0.16, 0.96);
  animation-fill-mode: backwards;
}

.Navigation-mobileMenuSection {
  animation-name: slideIn;
  animation-duration: 600ms;
  animation-timing-function: transform 0.35s cubic-bezier(0, 0, 0.16, 0.96);
  animation-fill-mode: backwards;
}

.Navigation-mobileMenuButton {
  animation-name: slideIn;
  animation-duration: 600ms;
  animation-timing-function: transform 0.35s cubic-bezier(0, 0, 0.16, 0.96);
  animation-fill-mode: backwards;
}

.NavIcon-label {
  animation-name: navIconLabelFadeIn;
  animation-direction: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes navIconLabelFadeIn {
  0% {
    opacity: 0;
    transform: translateX(-4px);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-24px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.BottomNavigationSlide {
  transition: transform 140ms ease-in-out;
  transform: translateY(0);

  &:not(.bottom-navigation-is-visible) {
    transform: translateY($bottom-navigation-height);
  }
}

.BottomNavigation {
  position: fixed;
  bottom: 0;
  height: $bottom-navigation-height;
}

.MobileOfficeSwitcherMenu {
  animation: mobile-office-switcher-menu-slide-up 400ms cubic-bezier(0.55, 0.085, 0.68, 0.53) 40ms 1 normal both;
}

@keyframes mobile-office-switcher-menu-slide-up {
  0% {
    transform: translateY(120px);
  }

  100% {
    transform: translateY(0);
  }
}
