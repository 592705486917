.SelectMenu-menu {
  position: absolute;
  opacity: 0;
  top: 24px;
  z-index: -1;
  display: none;

  &.is-open {
    animation-name: select-menu-entrance;
    animation-duration: 150ms;
    animation-fill-mode: forwards;
    display: block;
  }
}

.SelectMenu-option {
  &:hover {
    background-color: $blue-50;
  }
}

@keyframes select-menu-entrance {
  0% {
    opacity: 0;
    transform: translateY(12px);
    z-index: auto;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
    z-index: auto;
  }
}
