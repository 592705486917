.Consumable:hover {
    box-shadow: 2px 0px 22px rgba(87, 73, 109, 0.12);
    border-color: transparent !important;
}

.Consumable {
  &:hover .Consumable-dragHandle {
    visibility: visible;
  }

  .Consumable-dragHandle {
    visibility: hidden;

    &.is-dragging {
      visibility: visible;
    }

    &.is-touch-device {
      visibility: visible;
    }
  }
}
