.BottomSheet-scrim {
  bottom: 0;
  left: 0;
  position: fixed;
  transition: background-color 300ms, opacity 300ms;
  height: 100vh;
  width: 100vw;
  opacity: 1;

  @each $position in 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 {
    &.is-z-index-#{$position} {
      z-index: $modal-z-index + $position;
    }
  }
}

.BottomSheet-scrim.is-hidden {
  height: 0;
  overflow: hidden;
  background-color: transparent;
  opacity: 0;
}

.BottomSheet {
  @each $position in 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 {
    &.is-z-index-#{$position} {
      z-index: $modal-z-index + $position;
    }
  }

  position: fixed;

  -webkit-mask-image: -webkit-radial-gradient(white, black);
  background-color: white;
  height: -webkit-fill-available;
  width: 100vw;

  &.is-scrollable {
    overflow: auto;
  }

  &:not(.is-scrollable) {
    overflow: hidden;
  }

  &.is-full-screen {
    top: 0;
    left: 0;
  }

  &.is-overflow-visible {
    overflow: visible;
    -webkit-mask-image: none;
  }

  &.is-hidden {
    height: 0;
    overflow: hidden;
    padding-bottom: 0;
  }

  &.is-visible {
    &.has-animation {
      &.is-animation-slide-up {
        animation: bottom-sheet-slide-up 300ms;
      }

      &.is-animation-fade-in {
        animation: bottom-sheet-fade-in 300ms;
      }
    }

    @each $position in 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 {
      &.is-z-index-#{$position} {
        z-index: $modal-z-index + $position;
      }
    }
    opacity: 1;
  }

  &.is-hiding {
    @each $position in 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 {
      &.is-z-index-#{$position} {
        z-index: $modal-z-index + $position;
      }
    }

    &.has-animation {
      &.is-animation-slide-up {
        animation: bottom-sheet-slide-down 300ms;
      }
      &.is-animation-fade-in {
        animation: bottom-sheet-fade-out 300ms;
      }
      animation-fill-mode: forwards;
    }
  }

  &.is-hidden {
    z-index: -1;
    opacity: 0;
  }
}

@keyframes bottom-sheet-slide-up {
  0% {
    transform: translateY(300px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bottom-sheet-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bottom-sheet-slide-down {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    transform: translateY(600px);
    opacity: 0;
  }
}

@keyframes bottom-sheet-fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
