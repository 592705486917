@use './colors';

@mixin autofill-style {
  &:-webkit-autofill,
  &:-webkit-autofill:active,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 60px colors.$blue-50 inset !important;
    background-color: colors.$blue-50 !important;
    background-clip: content-box !important;
  }

  &:focus {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 60px colors.$white inset !important;
      background-color: colors.$white !important;
      background-clip: content-box !important;
    }
  }

  &:-internal-autofill-selected {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px colors.$blue-50;

    &:focus {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px colors.$white;
    }

    &.has-error {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px colors.$red-200;
    }
  }
}
