@use 'src/ds/media';

@mixin size-config($size, $config) {
  .PhonePrefix.#{$ds-class-name}.is-size-#{$size} {
    $font-size: map-get($config, font-size);
    $line-height: map-get($config, line-height);

    font-size: $font-size;

    .PhonePrefix-select {
      font-size: $font-size;
      padding-left: map-get($config, padding-x);
    }

    .PhonePrefix-display {
      padding-left: $icon-size-sm;
      position: absolute;
      z-index: -1;
      height: $line-height;
      display: inline-flex;
      align-items: center;
    }

    .PhonePrefix-iconContainer {
      height: $line-height;
    }
  }
}

.PhonePrefix-select {
  appearance: none;
  background-color: transparent;
  outline: none;
  border: none;
  margin: 0;
  z-index: 1;
  color: transparent;
  position: absolute;

  @include media.is-firefox {
    option {
      background-color: #6b676b; // TODO: what color is this? should it be a color from our design system?
      color: $white;
    }
  }
}

select.PhonePrefix-select {
  font: unset;
  font-family: $font-family;
}

.PhonePrefix-displayMeasurer {
  visibility: hidden;
  position: absolute;
}

.PhonePrefix {
  position: relative;
}

.PhonePrefix-iconContainer {
  position: absolute;
  display: inline-flex;
  align-items: center;
}

@each $size in sm, md, lg {
  @include size-config($size, map-get($field-size-config, $size));
}
