@use 'media';

.quill,
.quill .ql-editor {
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.quill .ql-editor {
  @include media.md-up {
    padding: 24px 16px;
    // min-height is 2 lines. 16px = line height, 24px = paddingY.
    min-height: calc(2 * 16px + 2 * 24px);
  }

  @include media.sm-down {
    padding: 16px;
    min-height: calc(2 * 16px + 2 * 16px);
  }
}

.quill .ql-snow.ql-toolbar {
  border: 1px solid $gray-50;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #f7f7f8;
}

.quill .ql-snow.ql-container {
  border: 1px solid $gray-50;
  border-top: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  a {
    color: $blue-500;
    text-decoration: none !important;
  }
}

.quill .ql-snow .ql-tooltip {
  border-radius: 8px;
  border: 1px solid $gray-50;
  padding: 6px 12px;
  z-index: 10;

  &:before {
    color: $gray-900;
  }

  input {
    border-radius: 4px;

    &::placeholder {
      color: transparent;
    }

    &:focus,
    &:focus-visible {
      border: 1px solid $blue-500;
      outline: none;
    }
  }
}

.RichTextDisplay {
  a {
    color: $blue-500;
  }

  &.ql-editor {
    padding: 0;
  }
}

.RichTextEditor {
  &.is-focused {
    .quill .ql-snow.ql-container {
      border-color: $blue-500;
    }

    .quill .ql-snow.ql-toolbar {
      border-top-color: $blue-500;
      border-left-color: $blue-500;
      border-right-color: $blue-500;
    }
  }

  &:not(.is-focused) {
    .quill .ql-snow.ql-container {
      border-top: 1px solid $gray-50;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }
}
