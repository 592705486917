$map-marker-dimension: 32px;

@mixin pinProps($dimension) {
  width: $dimension;
  height: $dimension;
  top: ($map-marker-dimension - $dimension) / 2;
  left: ($map-marker-dimension - $dimension) / 2;
}

.MapMarker-whitePin {
  @include pinProps(32px);

  position: absolute;

  svg .Icon-fill-color {
    fill: $white;
  }

  svg circle {
    r: 0;
  }
}

.MapMarker-innerPin {
  @include pinProps($map-marker-dimension - 2px);

  position: absolute;
  transition-property: width, height, top, left;
  transition-duration: 50ms;

  &.is-bluePin {
    svg .Icon-fill-color,
    svg circle {
      fill: $blue-500;
    }
  }

  &.is-goldPin {
    svg .Icon-fill-color {
      fill: $gold-500;
    }
  }
}

.MapMarker {
  position: relative;
  width: $map-marker-dimension;
  height: $map-marker-dimension;
  right: $map-marker-dimension / 2;
  bottom: $map-marker-dimension;

  svg {
    width: 100%;
  }

  &.is-not-active .MapMarker-innerPin.is-bluePin {
    circle {
      r: 0;
    }
  }

  &:hover {
    .MapMarker-innerPin.is-bluePin {
      @include pinProps($map-marker-dimension - 6px);
    }
  }

  &:active,
  &.is-active {
    .MapMarker-innerPin.is-bluePin {
      @include pinProps($map-marker-dimension - 4px);
    }
  }

  &.is-active {
    z-index: 2;

    .MapMarker-innerPin.is-bluePin svg circle {
      r: 4;
    }

    &:hover,
    &:active {
      .MapMarker-innerPin.is-bluePin {
        @include pinProps($map-marker-dimension - 6px);
      }
    }
  }

  &:hover,
  &:active {
    z-index: 3;
  }

  &.has-shadow {
    svg {
      filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
    }
  }
}

.PriceMapMarker {
  padding: 4px 12px;
  transition: transform 150ms;

  transform: scale(1);

  &:hover,
  &.is-active {
    transform: scale(1.05);
  }

  background-size: 100%;
  background-image: linear-gradient(94.99deg, #1ab2d9 28.48%, #0078ff 141.74%);
  position: relative;
  z-index: 2;
  border-radius: 100px;

  &:before {
    background-image: linear-gradient($blue-700, $blue-700);

    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: 1;
    transition: opacity 0.5s;
    border-radius: 100px;
  }

  &.is-visited:before {
    background-image: linear-gradient($gray-50, $gray-50);
  }

  &.is-active:before {
    background-image: linear-gradient($white, $white);
  }

  &:not(.is-liked),
  &.is-active-liked {
    &:before {
      opacity: 1;
    }
  }
}

.ClusterMarker {
  transition: transform 150ms;

  &:hover {
    transform: scale(1.05);
  }
}

.PriceMarker {
  .PriceMarker-price {
    transform: translateX(0);
    transition: transform 150ms;
    z-index: 3;
  }

  &.is-liked {
    z-index: 4;

    .PriceMarker-price {
      transform: translateX(23px);
    }
  }

  &:hover,
  &:active,
  .is-active {
    z-index: 5;
  }
}

.HeartMarker,
.OfficeSearchMarker {
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  background: $white;
  transform: scale(1);
  transition: transform 150ms;
  position: relative;
  z-index: 1;
  box-shadow: 2px 0px 22px rgba(87, 73, 109, 0.12);
}

.HeartMarker {
  &:not(.is-liked) {
    svg {
      fill: $gray-900;
    }
  }

  &.is-liked {
    z-index: 2;

    svg {
      fill: $red-700;
    }
  }

  &.is-active {
    z-index: 3;
  }

  &:hover,
  &:active {
    z-index: 5;
  }

  &:hover,
  &:active,
  &.is-active {
    transform: scale(1.05);
  }

  &:active,
  &.is-active {
    background: $red-700;

    svg {
      fill: $white;
    }

    .HeartMarker-text {
      color: $white;
    }
  }
}

.OfficeSearchMarker {
  &:hover,
  &:active,
  &.is-active {
    z-index: 10;
  }

  &:hover,
  &:active,
  &.is-active {
    transform: scale(1.05);
  }
}

.OfficeSearchMarker:not(.is-recommended) {
  &:active,
  &.is-active {
    background: $gray-900;

    svg {
      fill: $white;
    }

    .OfficeSearchMarker-text {
      color: $white;
    }
  }
}

.OfficeSearchMarker.is-recommended {
  background: $blue-200;
  color: $blue-600;

  svg {
    fill: $blue-600;
  }

  &:active,
  &.is-active {
    background: $blue-600;
    color: $white;

    svg {
      fill: $white;
    }

    .OfficeSearchMarker-text {
      color: $white;
    }
  }
}
