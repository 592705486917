.GoogleButton.#{$ds-class-name} {
  .Button.#{$ds-class-name} {
    &.is-size-sm {
      padding: 0 12px;
    }

    &.is-size-md {
      padding: 0 16px;
    }

    &.is-size-lg {
      padding: 0 24px;
    }
  }
}
