@use 'src/ds/media';
@use 'src/ds/text-utils';

$section-vertical-spacing: 24px;
$paragraph-vertical-spacing: 12px;

.SeoContent {
  h1 {
    margin-top: $component-vertical-spacing;
  }

  h2 {
    margin-top: $component-vertical-spacing;
  }

  h3 {
    margin-top: $component-vertical-spacing;
  }

  p { 
    margin-top: $paragraph-vertical-spacing;
    line-height: 1.4;
  }

  a {
    color: $blue-500;
  }
}
