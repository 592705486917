.fte-animation-step-1 {
  &.is-mobile {
    transform-origin: top left;
    width: 168px;
    left: calc(50% - 186px);
    top: 50%;
  }

  &:not(.is-mobile) {
    left: 0;
    top: 96px;
    width: 124px;
  }

  position: absolute;
  animation-duration: 2600ms;
  animation-fill-mode: both;
}

.fte-animation-step-1.card-1 {
  &.is-mobile {
    animation-name: fte-animation-step-1-card-1-mobile;
  }

  &:not(.is-mobile) {
    animation-name: fte-animation-step-1-card-1;
  }
}

.fte-animation-step-1.card-2 {
  &.is-mobile {
    animation-name: fte-animation-step-1-card-2-mobile;
  }

  &:not(.is-mobile) {
    animation-name: fte-animation-step-1-card-2;
  }
}

.fte-animation-step-1.card-3 {
  &.is-mobile {
    animation-name: fte-animation-step-1-card-3-mobile;
  }

  &:not(.is-mobile) {
    animation-name: fte-animation-step-1-card-3;
  }
}

.fte-animation-step-2 {
  animation-duration: 1000ms;
  animation-name: fte-fade-from-bottom;
  animation-fill-mode: both;
  position: absolute;
}

.fte-animation-step-3 {
  animation-duration: 2440ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  transform-origin: top;
  left: calc(50% - 157px);
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  border-radius: 50%;
}

.fte-animation-step-3.circle-1 {
  animation-name: fte-animation-step-3-circle-1;
}

.fte-animation-step-3.circle-2 {
  animation-name: fte-animation-step-3-circle-2;
}

.fte-animation-step-3.circle-3 {
  animation-name: fte-animation-step-3-circle-3;
}

.fte-animation-step-3.join-circle {
  animation-name: fte-animation-step-3-join-circle;

  &.is-xs {
    animation-name: fte-animation-step-3-xs-join-circle;
  }
}

.fte-animation-step-3.join-background {
  animation-name: fte-animation-step-3-join-background;
  width: 84px;
  height: 84px;
  background-color: rgba($blue-500, 0.1);
}

@keyframes fte-animation-step-1-card-1 {
  0% {
    opacity: 0.2;
    transform: translateX(181px);
  }

  20% {
    opacity: 1;
    transform: scale(1) translateX(78px);
  }

  40% {
    transform: scale(1.3871) translateX(54px);
  }

  60% {
    transform: scale(1) translateX(54px);
  }

  100% {
    transform: scale(1) translateX(54px);
  }
}

@keyframes fte-animation-step-1-card-2 {
  0% {
    opacity: 0.2;
    transform: scale(1) translateX(363px);
  }

  20% {
    opacity: 1;
    transform: scale(1) translateX(226px);
  }

  40% {
    transform: scale(1) translateX(250px);
  }

  60% {
    transform: scale(1.3871) translateX(163px);
  }

  80% {
    transform: scale(1) translateX(200px);
  }

  100% {
    transform: scale(1.3871) translateX(163px);
  }
}

@keyframes fte-animation-step-1-card-3 {
  0% {
    opacity: 0.2;
    transform: scale(1) translateX(576px);
  }

  20% {
    opacity: 1;
    transform: scale(1) translateX(374px);
  }

  40% {
    transform: scale(1) translateX(398px);
  }

  60% {
    transform: scale(1) translateX(398px);
  }

  80% {
    transform: scale(1.3871) translateX(263px);
  }

  100% {
    transform: scale(1) translateX(398px);
  }
}

@keyframes fte-animation-step-1-card-1-mobile {
  0% {
    opacity: 0.2;
    transform: scale(1) translateX(282px) translateY(-50%);
  }

  20% {
    opacity: 1;
    transform: scale(1) translateX(104px) translateY(-50%);
  }

  40% {
    transform: scale(1.5) translateX(42px) translateY(-50%);
  }

  60% {
    transform: scale(1) translateX(-123px) translateY(-50%);
  }

  80% {
    transform: scale(1) translateX(-307px) translateY(-50%);
  }

  100% {
    transform: scale(1) translateX(-123px) translateY(-50%);
  }
}

@keyframes fte-animation-step-1-card-2-mobile {
  0% {
    opacity: 0.2;
    transform: scale(1) translateX(467px) translateY(-50%);
  }
  20% {
    opacity: 1;
    transform: scale(1) translateX(288px) translateY(-50%);
  }

  40% {
    transform: scale(1) translateX(330px) translateY(-50%);
  }

  60% {
    transform: scale(1.5) translateX(42px) translateY(-50%);
  }

  80% {
    transform: scale(1) translateX(-123px) translateY(-50%);
  }

  100% {
    transform: scale(1.5) translateX(42px) translateY(-50%);
  }
}

@keyframes fte-animation-step-1-card-3-mobile {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0.2;
    transform: scale(1) translateX(498px) translateY(-50%);
  }
  60% {
    opacity: 1;
    transform: scale(1) translateX(330px) translateY(-50%);
  }

  80% {
    transform: scale(1.5) translateX(42px) translateY(-50%);
  }

  100% {
    transform: scale(1) translateX(330px) translateY(-50%);
  }
}

@keyframes fte-animation-step-3-circle-1 {
  0% {
    transform: translateX(149px) translateY(-50%);
    opacity: 0.2;
  }

  28% {
    transform: translateX(0px) translateY(-50%);
    opacity: 1;
  }

  100% {
    transform: translateX(0px) translateY(-50%);
    opacity: 1;
  }
}

@keyframes fte-animation-step-3-circle-2 {
  0% {
    opacity: 0;
    transform: translateX(57px) translateY(-50%);
  }

  40% {
    opacity: 0;
    transform: translateX(57px) translateY(-50%);
  }

  65% {
    opacity: 0;
    transform: translateX(57px) scale(0.7857) translateY(-50%);
  }

  77% {
    opacity: 1;
    transform: translateX(67px) scale(1) translateY(-50%);
  }

  100% {
    opacity: 1;
    transform: translateX(67px) scale(1) translateY(-50%);
  }
}

@keyframes fte-animation-step-3-circle-3 {
  0% {
    opacity: 0;
    transform: translateX(126px) translateY(-50%);
  }

  77% {
    opacity: 0;
    transform: translateX(126px) translateY(-50%);
  }

  78% {
    opacity: 0;
    transform: translateX(131px) scale(0.7857) translateY(-50%);
  }

  100% {
    transform: translateX(131px) scale(1) translateY(-50%);
    opacity: 1;
  }
}

@keyframes fte-animation-step-3-join-circle {
  0% {
    transform: translateX(518px) rotate(176deg) translateY(-50%);
    outline: 0px solid rgba($blue-500, 0);
  }

  27% {
    transform: translateX(85px) rotate(0) translateY(-50%);
    outline: 0px solid rgba($blue-500, 0);
  }

  28% {
    transform: translateX(85px) rotate(0) translateY(-50%);
    outline: 0px solid rgba($blue-500, 0);
  }

  40% {
    transform: translateX(107px) rotate(90deg) translateY(-50%);
    outline: 0px solid rgba($blue-500, 0);
  }

  50% {
    transform: translateX(107px) rotate(90deg) translateY(-50%);
    outline: 0px solid rgba($blue-500, 0);
  }

  55% {
    transform: translateX(113px) rotate(90deg) scale(0.7857) translateY(-50%);
    outline: 0px solid rgba($blue-500, 0.1);
    animation-timing-function: ease-in-out;
  }

  60% {
    transform: translateX(107px) rotate(90deg) scale(1) translateY(-50%);
    outline: 24px solid rgba($blue-500, 0.1);
  }

  77% {
    transform: translateX(182px) rotate(180deg) translateY(-50%);
    outline: 0 solid rgba($blue-500, 0);
  }

  100% {
    transform: translateX(225px) rotate(270deg) translateY(-50%);
    outline: 0px solid rgba($blue-500, 0);
  }
}

@keyframes fte-animation-step-3-xs-join-circle {
  0% {
    transform: translateX(518px) rotate(176deg) translateY(-50%);
  }

  27% {
    transform: translateX(85px) rotate(0) translateY(-50%);
  }

  28% {
    transform: translateX(85px) rotate(0) translateY(-50%);
  }

  40% {
    transform: translateX(107px) rotate(90deg) translateY(-50%);
  }

  50% {
    transform: translateX(107px) rotate(90deg) translateY(-50%);
  }

  55% {
    transform: translateX(113px) rotate(90deg) scale(0.7857) translateY(-50%);
    animation-timing-function: ease-in-out;
  }

  60% {
    transform: translateX(107px) rotate(90deg) scale(1) translateY(-50%);
  }

  77% {
    transform: translateX(182px) rotate(180deg) translateY(-50%);
  }

  100% {
    transform: translateX(225px) rotate(270deg) translateY(-50%);
  }
}

@keyframes fte-animation-step-3-join-background {
  0% {
    transform: translateX(518px) scale(1) translateY(-50%);
    opacity: 0;
  }

  50% {
    transform: translateX(108px) translateY(-50%);
    opacity: 0;
  }

  55% {
    transform: translateX(108px) scale(1) translateY(-50%);
    animation-timing-function: ease-in-out;
    opacity: 0.1;
  }

  60% {
    transform: translateX(108px) scale(1.4) translateY(-50%);
    opacity: 1;
  }

  77% {
    transform: translateX(182px) scale(1) translateY(-50%);
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateX(225px) translateY(-50%);
  }
}

@keyframes fte-fade-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(48px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
