@use 'src/ds/media';
@use 'src/ds/text-utils';

.PolicyPage {
  h3 {
    margin-top: 48px;
    margin-bottom: 24px;
    padding-top: 32px;
    border-top: 1px solid $gray-50;
  }

  h4 {
    color: $gray-600;
  }

  @include media.lg {
    @each $size, $config in $heading-config-lg {
      #{$size} {
        @include text-utils.specs($config);
        @include text-utils.defaultBold($config);
      }
    }
  }

  @include media.md {
    @each $size, $config in $heading-config-md {
      #{$size} {
        @include text-utils.specs($config);
        @include text-utils.defaultBold($config);
      }
    }
  }

  @include media.sm {
    @each $size, $config in $heading-config-sm {
      #{$size} {
        @include text-utils.specs($config);
        @include text-utils.defaultBold($config);
      }
    }
  }
}
