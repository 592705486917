@use './constants';

// 0 - 375px
@mixin xs {
  @media only screen and (max-width: constants.$screen-xs) {
    @content;
  }
}

// 0 - 767px
@mixin sm {
  @media only screen and (max-width: constants.$screen-sm - 1) {
    @content;
  }
}

// 768 - 1199
@mixin md {
  @media only screen and (min-width: constants.$screen-sm) and (max-width: constants.$screen-lg - 1) {
    @content;
  }
}

// >= 1200
@mixin lg {
  @media only screen and (min-width: constants.$screen-lg) {
    @content;
  }
}

// >= 768px
@mixin md-up {
  @media only screen and (min-width: constants.$screen-sm) {
    @content;
  }
}

// <= 767px
@mixin sm-down {
  @media only screen and (max-width: constants.$screen-sm - 1) {
    @content;
  }
}

@mixin is-ios-browsers-and-safari {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin ios-safari {
  @supports (-webkit-touch-callout: none) {
    @content;
  }
}

@mixin is-firefox {
  @-moz-document url-prefix() {
    @content;
  }
}

@mixin is-chrome {
  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    @content;
  }
}
