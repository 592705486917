.Snackbar.#{$ds-class-name} {
  &.enter {
    animation: 100ms enter;
  }

  &.exit {
    animation: 100ms exit;
  }

  @keyframes enter {
    from {
      bottom: 0;
      opacity: 0;
    }

    to {
      bottom: 48px;
      opacity: 1;
    }
  }

  @keyframes exit {
    from {
      bottom: 48px;
      opacity: 1;
    }

    to {
      bottom: 0;
      opacity: 0;
    }
  }
}
